.history {
  padding-top: 100px;
  padding-bottom: 120px;

  @media screen and (max-width: 960px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media screen and (max-width: 670px) {
    padding-top: 50px;
    padding-bottom: 25px;
  }

  &__title {
    text-align: center;
  }

  &__text {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;

    @media screen and (max-width: 960px) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
    @media screen and (max-width: 670px) {
      font-size: 14px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  
  &__block {
    overflow: hidden;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -40px;
    margin-right: -40px;
    position: relative;

    @media screen and (max-width: 960px) {
      margin-left: -30px;
      margin-right: -30px;
    }

    @media screen and (max-width: 670px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &__col {
    padding-left: 40px;
    padding-right: 40px;
    width: 50%;

    @media screen and (max-width: 960px) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @media screen and (max-width: 670px) {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    &:nth-child(2n-1) {
      border-right: 1px solid #e6e6e6;

      @media screen and (max-width: 670px)  {
        border: 0;
      }
    }
    &:nth-child(2n) {
      border-left: 1px solid #e6e6e6;

      @media screen and (max-width: 670px)  {
        border: 0;
      }
    }
  }
  &__item-img {

    img {
      width: 100%;
      display: block;
    }

  }
  &__item-text {

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__name {
    font-size: 23px;
    color: #1e1e1e;
    font-weight: 500;
    margin-bottom: 30px;
    position: relative;
    margin-top: 20px;

    @media screen and (max-width: 960px) {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 18px;
    }

    @media screen and (max-width: 670px) {
      font-size: 16px;
    }

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background: #e6e6e6;
      position: absolute;
      top: 17px;
      left: -40px;

      @media screen and (max-width: 960px) {
        left: -30px;
        top: 13px;
      }

      @media screen and (max-width: 670px) {
        display: none;
      }
    }
  }
  &__year {
    color: #c8c8c8;
  }
  &__info {
    margin-bottom: 20px;

    @media screen and (max-width: 960px) {
      font-size: 14px;
      line-height: 1.6;
    }
  }


}
.history__row--reverse .history__item-text {
  text-align: right;

  @media screen and (max-width: 670px) {
    text-align: left;
    order: 1;
  }
}
.history__row--reverse .history__name:before {
  left: auto;
  right: -40px;

  @media screen and (max-width: 960px) {
    right: -30px;
  }
}
.history__row:first-child .history__col {
  padding-top: 20px;

  @media screen and (max-width: 960px) {
    padding-top: 10px;
  }
  @media screen and (max-width: 670px) {
    padding-top: 0;
  }
}
.history__row:last-child .history__col {
  padding-bottom: 20px;

  @media screen and (max-width: 960px) {
    padding-bottom: 10px;
  }
  @media screen and (max-width: 670px) {
    padding-bottom: 0;
  }
}