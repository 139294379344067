.search {
  position: relative;

  &__btn-for-search {
    display: block;
    width: 18px;
    height: 18px;
    background: url(../images/search-icon-white.svg) no-repeat center;
    background-size: contain;
    cursor: pointer;
    position: absolute;
    right: 0;
  }

  &__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: relative;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s;

    &--open {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__input {
    font-family: 'Roboto', sans-serif;  
    font-weight: 300;
    font-size: 14px;
    color: #ffffff;
    width: 200px;
    background-color: transparent;

    &::-webkit-input-placeholder {color:#ffffff; opacity: 0.6;}
    &::-moz-placeholder          {color:#ffffff; opacity: 0.6;}/* Firefox 19+ */
    &:-moz-placeholder           {color:#ffffff; opacity: 0.6;}/* Firefox 18- */
    &:-ms-input-placeholder      {color:#ffffff; opacity: 0.6;}

    &::-ms-clear {  display: none; width : 0; height: 0; }
    &::-ms-reveal {  display: none; width : 0; height: 0; }
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration { display: none; }
  }
  &__search-btn {
    width: 18px;
    height: 18px;
    background: url(../images/search-icon-white.svg) no-repeat center;
    background-size: contain;
  }
}