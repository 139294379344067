.team {
  background: #f6f5f5;
  padding-top: 90px;
  padding-bottom: 90px;

  @media screen and (max-width: 960px) {
    padding-top: 70px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 670px) {
    padding-top: 50px;
    padding-bottom: 35px;
  }

  &__title {
    text-align: center;
    margin-bottom: 60px;

    @media screen and (max-width: 960px) {
      margin-bottom: 39px;
    }
    @media screen and (max-width: 670px) {
      margin-bottom: 30px;
    }
  }
  &__row {
    justify-content: center;

    @media screen and (max-width: 1200px) {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
  &__col {
    width: 20%;
    margin-bottom: 32px;

    @media screen and (max-width: 1200px) {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 960px) {
       width: 33.33%;
    }

    @media screen and (max-width: 670px) {
      width: 50%;
      margin-bottom: 14px;
    }
  }
  &__item {
    height: 100%;
    background: #ffffff;
  }
  &__photo {

    img {
      width: 100%;
    }
  }
  &__text {
    padding: 20px 27px 24px;

    @media screen and (max-width: 1200px) {
      padding: 15px 20px 17px;
    }
    @media screen and (max-width: 670px) {
      padding: 5px 10px 10px;
    }
  }
  &__name {
    font-size: 20px;
    font-weight: 500;
    color: #1e1e1e;
    margin-bottom: 5px;

    @media screen and (max-width: 670px) {
      font-size: 18px;
    }
  }
  &__position {
    font-size: 15px;

    @media screen and (max-width: 670px) {
      font-size: 14px;
    }
  }
}