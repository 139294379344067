.form {

  &__item {
    margin-bottom: 20px;
    width: 100%;

    @media screen and (max-width: 670px) {
      margin-bottom: 15px;
    }

  }
  &__item--text {
    height: calc(100% - 20px);

    @media screen and (max-width: 670px) {
      height: auto;
    }
  }
  &__item--btn {
    text-align: center;
    margin-top: 20px;

    @media screen and (max-width: 960px) {
      margin-top: 0;
    }
  }
  &__additional-text {
    font-size: 12px;
    color: rgba(255,255,255,0.5);

  }
  &__message {
    margin-top: 20px;

    @media screen and (max-width: 670px) {
      font-size: 12px;
      margin-top: 10px;
    }
  }
  &__message--ok {    
    color: #A8E4A0;
    font-weight: 500;
    display: none;

    &--visible {
      display: block;
    }
  }
  &__message--error {
    color: red;
    font-weight: 500;
    display: none;

    &--visible {
      display: block;
    }
  }
}
/*Input*/
.form__input {
  font-family: 'Roboto', sans-serif;  
  font-weight: 300;
  color: #ffffff;
  border: 2px solid #ffffff;
  width: 100%;
  height: 50px;
  padding-left: 16px;
  font-size: 16px;
  background-color: transparent;

  &::-webkit-input-placeholder {color:#ffffff; opacity: 1;}
  &::-moz-placeholder          {color:#ffffff; opacity: 1;}/* Firefox 19+ */
  &:-moz-placeholder           {color:#ffffff; opacity: 1;}/* Firefox 18- */
  &:-ms-input-placeholder      {color:#ffffff; opacity: 1;}

  @media screen and (max-width: 670px) {
    height: 42px;
    font-size: 14px;
  }

  &--error {
    border-color: red;
    color: red;

    &::-webkit-input-placeholder {color:red; opacity: 1;}
    &::-moz-placeholder          {color:red; opacity: 1;}/* Firefox 19+ */
    &:-moz-placeholder           {color:red; opacity: 1;}/* Firefox 18- */
    &:-ms-input-placeholder      {color:red; opacity: 1;}
  }
}
/*Textares*/
.form__textarea {
  font-family: 'Roboto', sans-serif;  
  font-weight: 300;
  color: #ffffff;
  border: 2px solid #ffffff;
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-top: 13px;
  font-size: 16px;
  background-color: transparent;

  &::-webkit-input-placeholder {color:#ffffff; opacity: 1;}
  &::-moz-placeholder          {color:#ffffff; opacity: 1;}/* Firefox 19+ */
  &:-moz-placeholder           {color:#ffffff; opacity: 1;}/* Firefox 18- */
  &:-ms-input-placeholder      {color:#ffffff; opacity: 1;}

  @media screen and (max-width: 670px) {
    font-size: 14px;
  }
}
/*button*/
.form__submit-btn {
  font-family: 'RussoOne', sans-serif;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.1em;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  border: 2px solid #76beea;
  background: #76beea;
  padding: 15px 32px 13px;
  transition: all 0.5s;
  margin: auto;
  width: 320px;

  // @media screen and (max-width: 1200px) {
  //   margin-top: 10px;
  //   font-size: 11px;
  //   padding: 10px 16px;
  // }
  @media screen and (max-width: 670px) {
    font-size: 11px;
    border-width: 1px;
    width: auto;
    padding: 13px 50px;
  }

  &:hover {
    color: #76beea;
    background: transparent;
  }
}