.contact-form {
  background: url(../images/about/contact-form-bg.jpg) no-repeat center;
  background-size: cover;
  padding-top: 110px;
  padding-bottom: 105px;

  @media screen and (max-width: 960px) {
    padding-top: 70px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 670px) {
    padding-top: 50px;
    padding-bottom: 35px;
  }

  &__title {
    text-align: center;
    color: #ffffff;
    margin-bottom: 45px;

    @media screen and (max-width: 670px) {
      margin-bottom: 30px;
    }
  }

  &__row {
    margin-left: -20px;
    margin-right: -20px;

    @media screen and (max-width: 960px) {
      padding-left: -16px;
      padding-right: -16px;
    }

    @media screen and (max-width: 670px) {
      margin-left: -7px;
      margin-right: -7px;
    }
  }
  &__col {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;

    @media screen and (max-width: 960px) {
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 670px) {
      padding-left: 7px;
      padding-right: 7px;
      margin-bottom: 0;
    }
  }
  &__col--send {
    text-align: center;
  }
}