.tupes-block {
  padding-top: 120px;
  padding-bottom: 100px;
  background: url(../images/tupes-block-bg.jpg) no-repeat;
  background-size: cover;
  color: #ffffff;

  @media screen and (max-width: 960px) {
    padding-top: 60px;
    padding-bottom: 41px;
  }

  @media screen and (max-width: 670px) {
    padding-top: 30px;
    padding-bottom: 31px;
  }

  &__col-small {
    width: 25%;

    @media screen and (max-width: 960px) {
      width: 100%;
      margin-bottom: 24px;
    }

    @media screen and (max-width: 670px) {
      width: 100%;
      margin-bottom: 13px;
    }
  }
  &__col-large {
    width: 37.5%;
    padding-top: 5px;

    @media screen and (max-width: 960px) {
      width: 100%;
      margin-bottom: 0;
      padding-top: 0;
    }

    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }

  &__title {
    text-transform: uppercase;
    max-width: 260px;
    margin-bottom: 35px;
    line-height: 1.6;
    color: #ffffff;

    @media screen and (max-width: 960px) {
      max-width: 100%;
    }
    @media screen and (max-width: 670px) {
      margin-bottom: 15px;
    }
  }
  &__list {
    list-style: none;

    li {
      padding-left: 27px;
      position: relative;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;

      @media screen and (max-width: 670px) {
        margin-bottom: 5px;
        font-size: 14px;
      }

      &:before {
        content: '';
        display: block;
        width: 16px;
        height: 12px;
        background: url(../images/list-li.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 5px;

        @media screen and (max-width: 670px) {
          width: 8px;
          height: 6px;
        }
      }
    }
  }
  &__p {
    margin-bottom: 24px;
    line-height: 1.6;

    @media screen and (max-width: 670px) {
      margin-bottom: 13px;
      font-size: 14px;
    }
  }
  &__subtitle {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 24px;
    line-height: 1.6;
    color: #ffffff;
    text-transform: none;

    @media screen and (max-width: 670px) {
      margin-bottom: 13px;
      font-size: 16px;
    }
  }
}