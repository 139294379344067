.trus-us-slider {


  &__item {
    display: block;
    width: 100%;
    border: 1px solid #e6e6e6;
    height: 0;
    padding-bottom: 55.5%;

    @media screen and (max-width: 1500px) {
     
    }

    @media screen and (max-width: 670px) {
      width: 25vw;
       margin-right: -1px;
    }
  }
  &__img {
    display: block;
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    margin: auto;
  }
}

.trus-us-slider.owl-carousel .owl-item .trus-us-slider__img {
    width: auto;   
    max-width: 90%;
    max-height: 90%;
}
.trus-us-slider.owl-carousel .owl-nav {
  position: absolute;
  right: -100px;
  width: 100px;
  height: 100%;
  top: 0;
  bottom: 0;

  @media screen and (max-width: 1500px) {
    right: -30px;
  }
  @media screen and (max-width: 670px) {
    right: 0;
  }
}
.trus-us-slider.owl-carousel .owl-nav .owl-next {
  width: 100%;
  height: 100%; 
  background: none;
  position: relative;
  @media screen and (max-width: 670px) {
    width: calc( 30vw/3 );
    position: absolute;
    right: 0;
  }

  &:before {
    content: '';
    display: block;
    background: url(../images/arrow-right-black.svg) no-repeat center, #d6ebf9;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    margin: auto;
    top: 0; bottom: 0;

    @media screen and (max-width: 670px)  {
      width: calc( 30vw/3 );
      // background: rgba(0,104,160,0.5);
      background-size: 10px 8px;
      border-radius: 0;
      height: 100%;
      right: 0;
    }
  }
}

@media screen and (min-width: 961px) {
  .trus-us-slider__img {
        filter: grayscale(80%);
    -webkit-filter: grayscale(80%);
    -moz-filter: grayscale(80%);
    -ms-filter: grayscale(80%);
    -o-filter: grayscale(80%);
    filter: url(desaturate.svg#greyscale);
    filter: gray;
    -webkit-filter: grayscale(1);
  }  
  .trus-us-slider__item:hover .trus-us-slider__img {
    filter: none;
  }
  .trus-us-slider__item {
    border: 1px solid rgba(230,230,230, 0.6);
  }
  .trus-us-slider__item:hover {
      border: 1px solid #e6e6e6;
  }
}