.about {
  background-color: #f6f5f5;
  padding-bottom: 100px;

  @media screen and (max-width: 960px) {
    padding-bottom: 50px;
  }

  &__info {
    margin-top: 70px;

    @media screen and (max-width: 960px) {
      margin-top: 15px;
    }

    @media screen and (max-width: 670px) {
      margin-top: 10px;
    }
  }

  &__item {

  }
  &__title {
    font-size: 23px;
    font-weight: 500;
    color: #1e1e1e;
    margin-bottom: 27px;
    margin-top: 10px;

    @media screen and (max-width: 670px) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  &__text {
    line-height: 1.6;
    margin-bottom: 21px;

    @media screen and (max-width: 670px) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
  &__col-small {
    padding-right: 16px;

    @media screen and (max-width: 670px) {
      padding-right: 0;
    }
  }
  &__stages {
    list-style: none;
  }
  &__stage {
    font-size: 18px;
    color: #1e1e1e;
    margin-bottom: 25px;
    padding-left: 50px;
    position: relative;
    font-weight: 400;

    @media screen and (max-width: 670px) {
      font-size: 14px;
      margin-bottom: 10px;
      padding-left: 25px;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: 4px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &--projection {
      &:before {
        width: 28px;
        height: 20px;
        background-image: url(../images/about/projection.svg);

        @media screen and (max-width: 670px) {
          width: 14px;
          height: 10px;
        }
      }
    }
    &--expertise {
      &:before {
        width: 24px;
        height: 29px;
        background-image: url(../images/about/expertise-icon.svg);

        @media screen and (max-width: 670px) {
          width: 12px;
          height: 15px;
        }
      }
    }
    &--production {
      &:before {
        width: 28px;
        height: 28px;
        background-image: url(../images/about/production-icon.png);

        @media screen and (max-width: 670px) {
          width: 14px;
          height: 14px;
        }
      }
    }
    &--assembling {
      &:before {
        width: 23px;
        height: 23px;
        background-image: url(../images/about/assembling-icon.png);

        @media screen and (max-width: 670px) {
          width: 12px;
          height: 12px;
        }
      }
    }
    &--warranty {
      &:before {
        width: 23px;
        height: 25px;
        background-image: url(../images/about/warranty-icon.png);

        @media screen and (max-width: 670px) {
          width: 12px;
          height: 13px;
        }
      }
    }
  }
  &__img {
    margin-top: 10px;

    @media screen and (max-width: 670px)  {
      margin-top: 20px;
    }
  }
  &__img img {
    width: 100%;
  }
}