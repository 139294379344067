.burger {
  display: none;

  @media screen and (max-width: 960px) {
    width: 30px;
    height: 30px;
    display: block;
    background: url(../images/burger.svg) no-repeat center, #ffffff;
    background-size: 13px 10px;

    &--open {
      background: url(../images/burger-close.svg) no-repeat center, #ffffff;
      background-size: 13px 13px;
    }
  }
}