.footer-menu {
  list-style: none;

  &__item {
    
  }
  &__link {
    font-size: 15px;
    color: #ffffff;
    text-decoration: none;
    margin-bottom: 17px;
    display: inline-block;

    @media screen and (max-width: 960px) {
      margin-bottom: 12px;
    }
    @media screen and (max-width: 670px) {
      font-size: 14px;
      margin-bottom: 5px;
    }

    &--active {
      color: #76beea;
    }

    @media screen and (min-width: 961px) {
      &:hover {
        color: #76beea;
      }
    }

    &--btn {
      font-family: 'RussoOne', sans-serif;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-size: 13px;
      color: #0068a0;
      padding-bottom: 5px;
      position: relative;

      @media screen and (max-width: 670px) {
        font-size: 10px;
        margin-top: 3px;
      }

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: #0068a0;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &:hover {

        &:after {
          background: #76beea;
        }
      }
    }
  }
}