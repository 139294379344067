.top-line {
  background-color: #0068a0;

  @media screen and (max-width: 960px) {
    display: none;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 0;
    align-content: center;
    align-items: center;
  }
  &__main {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }
  &__item {
    color: #ffffff;
    font-size: 14px;

    &--venue {
      padding: 0 40px 0 23px;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 15px;
        height: 18px;
        position: absolute;
        top: 0;
        left: 0;
        background: url(../images/venue-icon-blue.svg) no-repeat center;
        background-size: contain;
      }
    }

    &--mail {
      padding: 0 45px 0 28px;
      position: relative;
      text-decoration: none;

      &:before {
        content: '';
        display: block;
        width: 19px;
        height: 14px;
        position: absolute;
        top: 4px;
        left: 0;
        background: url(../images/mail-icon-blue.svg) no-repeat center;
        background-size: contain;
      }
    }
  }

  &__contact-btn {
    font-family: 'RussoOne', sans-serif;
    font-size: 10px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #76beea;
    position: relative;
    text-decoration: none;
    padding-right: 28px;

    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
      top: 0;
      background: url(../images/arrow-top-contact-white.svg) no-repeat center,
            #76beea;
    }

    &--text {
      position: relative;
      display: inline-block;
      padding-bottom: 5px;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: #76beea;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}