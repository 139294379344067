.promo-video {
  margin-top: -50px;
  margin-bottom: 90px;
  position: relative;
  z-index: 5;

  @media screen and (max-width: 960px) {
    margin-top: 30px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 670px) {
    margin-bottom: 25px;
  }

  &__row {
    align-content: flex-end;
    align-items: flex-end;

    @media screen and (max-width: 960px) {
      align-content: flex-start;
      align-items: flex-start;
    }
  }

  &__video {
    @media screen and (max-width: 670px) {
      order: 1;
      margin-top: 30px;
    }
  }

  &__info {
    padding-top: 70px;
    padding-left: 70px;

    @media screen and (max-width: 1280px) {
      padding-left: 35px;
    }
    @media screen and (max-width: 960px) {
      padding-top: 0;
    }
    @media screen and (max-width: 670px) {
      padding-left: 0;
    }
  }
  &__title {
    font-family: 'RussoOne', sans-serif;
    font-size: 25px;
    letter-spacing: 0.1em;
    color: #1e1e1e;
    text-transform: uppercase;
    margin-bottom: 40px;

    @media screen and (max-width: 960px) {
      margin-bottom: 20px;
      font-size: 21px;
    }
    @media screen and (max-width: 670px) {
      font-size: 16px;
    }
  }
  &__text {

    @media screen and (max-width: 960px) {
      font-size: 14px;
    }

    p {
      margin-bottom: 20px;

      @media screen and (max-width: 960px) {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}