.about-jobs {
  background: #f6f5f5;

  &__col-info {
    @media screen and (max-width: 960px) {
      order: 1;
      padding: 50px 0 50px;
    }
    @media screen and (max-width: 670px) {
      padding: 30px 0;
    }
  }
  &__col-jobs {

  }

  &__more {
    padding-right: 40px;
    margin-left: calc((100%*2 - 1340px)/2); /* Половина от: 100% ширина экрана - ширина inner (с учетом padding) */
    padding-left: 30px; /* Padding как в Inner */
    height: 100%;

    @media screen and (max-width: 1340px) {
      margin-left: 0;
    }
    @media screen and (max-width: 960px) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media screen and (max-width: 670px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  
  &__info {
    display: none;
    height: 100%;
    transition: all 0.5s;

    &--hide {
      display: flex;
      animation: opacityFunctHide 1s 1;
      opacity: 0;
    }
    &--active {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: flex-start;
      // align-items: flex-start;
      animation: opacityFunct 1s 1;
    }
  }
  &__info-title {
    font-size: 25px;
    margin-bottom: 40px;

    @media screen and (max-width: 1200px) {
      font-size: 18px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 670px) {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  &__info-text {
    margin-bottom: 28px;

    @media screen and (max-width: 1200px) {
      font-size: 14px;
      margin-bottom: 14px;
    }
  }
  &__info-btn {
    font-size: 13px;
    letter-spacing: 0.1em;
    color: #76beea;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    border: 2px solid #76beea;
    padding: 15px 32px 13px;
    font-family: 'RussoOne', sans-serif;
    transition: all 0.5s;
    margin-top: 20px;
    align-self: flex-start;

    @media screen and (max-width: 1200px) {
      margin-top: 10px;
      font-size: 11px;
      padding: 10px 16px;
    }
    @media screen and (max-width: 670px) {
      margin-top: 5px;
      border-width: 1px;
    }

    &:hover {
      color: #ffffff;
      background: #76beea;
    }
  }

  &__item {
    display: block;
    position: relative;
    z-index: 5;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
      margin: auto;
      background: rgba(0,104,160,0);
      transition: all 1s;
    }

    &--active {
      &:before {
        background: rgba(0,104,160,0.8);
      }
    }
  }
  &__bg {
    z-index: 1;
    img {
      display: block;
      width: 100%;
    }
  }
  &__title {
    font-size: 20px;
    color: #ffffff;

    @media screen and (max-width: 1500px) {
      font-size: 16px;
    }
    @media screen and (max-width: 670px) {
      font-size: 10px;
    }
  }
  &__inner {
    border: 4px solid rgba(118,190,234,0);
    width: calc( 100% - 60px );
    height: calc( 100% - 60px );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    transition: all 1s;
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.5s;

    @media screen and (max-width: 1500px) {
      width: calc( 100% - 40px );
      height: calc( 100% - 40px );
    }
    @media screen and (max-width: 960px) {
      width: calc( 100% - 60px );
      height: calc( 100% - 60px );
    }
    @media screen and (max-width: 670px) {
      width: calc( 100% - 16px );
      height: calc( 100% - 16px );
      padding: 10px;
      border-width: 1px;
    }
  }
}
.about-jobs__item--active {
  .about-jobs__inner {
    border: 4px solid rgba(118,190,234,1);
    transition: all 2s;

    @media screen and (max-width: 670px) {
      border-width: 2px;
    }
  }
}

@keyframes opacityFunct {
  0% {
    opacity: 0%
  }
  100% {
    opacity: 100%
  }
}
@keyframes opacityFunctHide {
  0% {
    opacity: 100%
  }
  100% {
    opacity: 0%
  }
}