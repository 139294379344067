.video-youtube {
  position: relative;
  padding-bottom: 56.25%; /* 56.25% задаёт высоту контейнера для 16:9 (если 4:3 -- поставьте 75%) */
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__img {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: url(https://www.admiralpools.ru/wp-content/themes/admiral/img/play-white-small.png) no-repeat center;
    }

    img {
      width: 100%;
    }

    &--hide {
      display: none;
    }
  }

}