.top-info {
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
  position: relative;  

  @media screen and (max-width: 960px) {
    box-shadow: none;
    background: #0068a0;    
  }

  &__container,
  &__left,
  &__right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
  &__left {
    justify-content: flex-start;

     @media screen and (max-width: 960px) {
      max-width: 40%;
    }
  }
  &__right {
    justify-content: flex-end;

     @media screen and (max-width: 960px) {
      max-width: 60%;
    }
  }

  &__logo {
    margin-right: 75px;

    @media screen and (max-width: 1600px) {
      margin-right: 30px;
    }

    @media screen and (max-width: 1100px) {
      max-width: 130px;
    }

    @media screen and (max-width: 960px) {
      margin-right: 15px;
      padding-top: 5px;
      padding-bottom: 10px;
      max-width: 100%;
    } 

    &--white {
      display: none;

      @media screen and (max-width: 960px) {
        display: block;
      }
    }
    &--black {

      @media screen and (max-width: 960px) {
        display: none;
      }
    }
  }

  &__main-data {
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;

    @media screen and (max-width: 1280px) {
      margin-right: 0;
    }

    @media screen and (max-width: 960px) {
      margin-right: 10px;
    }
  }

  &__phone {
    font-size: 22px;
    font-weight: 500;
    padding-left: 23px;
    position: relative;
    text-decoration: none;
    color: #1e1e1e;

    @media screen and (max-width: 1600px) {
      font-size: 18px;
    }

    @media screen and (max-width: 960px) {
      color: #ffffff;
      font-size: 14px;
      padding-left: 13px;
    }

    &:before {
      content: '';
      display: block;
      width: 11px;
      height: 20px;
      background: url(../images/phone-icon-blue.png);
      background-size: contain;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;

      @media screen and (max-width: 960px) {
        width: 7px;
        height: 12px;
      }
    }
  }
  &__city {
    font-size: 13px;

    @media screen and (max-width: 960px) {
      color: #ffffff;
    }
  }
  &__btn-block {
    @media screen and (max-width: 1280px) {
      display: none;
    }
  }
  &__btn {
    font-size: 12px;
    letter-spacing: 0.1em;
    color: #76beea;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    border: 2px solid #76beea;
    padding: 11px 28px 9px;
    font-family: 'RussoOne', sans-serif;
    transition: all 0.5s;

    @media screen and (max-width: 1600px) {
      padding: 11px 15px 9px;
    }

    &:hover {
      color: #ffffff;
      background: #76beea;
    }
  }
}
@media screen and (min-width: 961px) {
  .top-info--opacity {
    background: none;
    box-shadow: none;

    .top-info__logo--black {
      display: none;
    }
    .top-info__logo--white {
      display: block;
    }
    .menu__link {
      color: #ffffff;
    }
    .menu__link--active,
    .menu__item:hover .menu__link {
      color: #76beea !important;
      border-color: #76beea !important;
    }
    .top-info__phone {
      color: #ffffff;
    }
    .top-info__city {
      color: #ffffff;
    }
  }
}