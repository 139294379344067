@font-face {
  font-family: 'RussoOne';
  src: url('../fonts/russoone.woff2') format('woff2'), url('../fonts/russoone.woff') format('woff'), url('../fonts/russoone.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  outline: none;
}
main,
article,
section,
footer,
header,
aside,
canvas {
  display: block;
}
section,
header,
footer {
  position: relative;
}
html {
  height: 100%;
}
body {
  min-width: 320px;
  min-height: 100%;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0em;
  color: #646464;
  position: relative;
  font-size: 16px;
  /*inner 1280 + 30px*2 */
}
@media screen and (max-width: 1920px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 1600px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 1500px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 1440px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 1340px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 1280px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 1200px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 1100px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 1024px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 960px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 670px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 576px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 961px) {
  body {
    position: relative;
  }
}
body.on-popup {
  overflow: hidden;
}
section:after {
  content: '';
  display: block;
  clear: both;
}
button,
input,
select {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
button {
  cursor: pointer;
  line-height: 1.4;
}
h1,
h2,
h3 {
  font-family: 'RussoOne', sans-serif;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #1e1e1e;
}
h1 {
  font-size: 35px;
  text-align: center;
}
@media screen and (max-width: 670px) {
  h1 {
    font-size: 18px;
  }
}
h2 {
  font-size: 25px;
}
@media screen and (max-width: 670px) {
  h2 {
    font-size: 16px;
  }
}
h3 {
  font-size: 21px;
}
@media screen and (max-width: 670px) {
  h3 {
    font-size: 14px;
  }
}
strong {
  font-weight: 500;
}
img {
  max-width: 100%;
}
a {
  color: #0068a0;
}
.inner {
  position: relative;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 30px;
}
@media screen and (max-width: 670px) {
  .inner {
    padding: 0 15px;
  }
}
.inner:after {
  content: '';
  display: block;
  clear: both;
}
.inner--all {
  padding: 0;
  overflow: hidden;
}
@media screen and (max-width: 960px) {
  .inner--all {
    padding: 0;
  }
}
@media screen and (max-width: 670px) {
  .inner--all {
    padding: 0;
  }
}
.inner--max {
  padding: 0 60px;
}
@media screen and (max-width: 1600px) {
  .inner--max {
    padding: 0 30px;
  }
}
@media screen and (max-width: 670px) {
  .inner--max {
    padding: 0 15px;
  }
}
.inner--large {
  padding: 0 60px;
  max-width: 1920px;
}
@media screen and (max-width: 1600px) {
  .inner--large {
    padding: 0 30px;
  }
}
@media screen and (max-width: 670px) {
  .inner--large {
    padding: 0 15px;
  }
}
.inner--container {
  max-width: 1340px;
  padding: 0 30px;
}
@media screen and (max-width: 670px) {
  .inner--container {
    padding: 0 15px;
  }
}
.inner--allright {
  position: relative;
  max-width: 100%;
  height: 100%;
  margin: 0 0 0 auto;
  padding: 0 0 0 25px;
  width: calc(1310px + (100vw - 1310px)/2);
  overflow: hidden;
}
@media screen and (max-width: 670px) {
  .inner--allright {
    padding-left: 15px;
    padding-right: 40px;
  }
}
.inner--allright--inside {
  max-width: 1280px;
  overflow: visible;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -16px;
  margin-right: -16px;
  position: relative;
}
@media screen and (max-width: 670px) {
  .row {
    margin-left: -7px;
    margin-right: -7px;
  }
}
.row--center {
  justify-content: center;
}
.row--start {
  justify-content: flex-start;
}
.row--null {
  margin-left: 0;
  margin-right: 0;
}
@media screen and (max-width: 670px) {
  .row--null {
    margin-left: 0;
    margin-right: 0;
  }
}
.row--small {
  margin-left: -7px;
  margin-right: -7px;
}
.col {
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (max-width: 670px) {
  .col {
    padding-left: 7px;
    padding-right: 7px;
  }
}
.row--small .col {
  padding-left: 7px;
  padding-right: 7px;
}
.row--null .col {
  padding-left: 0;
  padding-right: 0;
}
.col-1 {
  width: 8.33%;
}
@media screen and (max-width: 670px) {
  .col-1 {
    width: 100%;
  }
}
.col-2 {
  width: 16.66%;
}
@media screen and (max-width: 670px) {
  .col-2 {
    width: 100%;
  }
}
.col-3 {
  width: 25%;
}
@media screen and (max-width: 670px) {
  .col-3 {
    width: 100%;
  }
}
.col-4 {
  width: 33.33%;
}
@media screen and (max-width: 670px) {
  .col-4 {
    width: 100%;
  }
}
.col-5 {
  width: 41.66%;
}
@media screen and (max-width: 670px) {
  .col-5 {
    width: 100%;
  }
}
.col-6 {
  width: 50%;
}
@media screen and (max-width: 670px) {
  .col-6 {
    width: 100%;
  }
}
.col-7 {
  width: 58.33%;
}
@media screen and (max-width: 670px) {
  .col-7 {
    width: 100%;
  }
}
.col-8 {
  width: 66.66%;
}
@media screen and (max-width: 670px) {
  .col-8 {
    width: 100%;
  }
}
.col-9 {
  width: 75%;
}
@media screen and (max-width: 670px) {
  .col-9 {
    width: 100%;
  }
}
.col-10 {
  width: 83.33%;
}
@media screen and (max-width: 670px) {
  .col-10 {
    width: 100%;
  }
}
.col-11 {
  width: 91.66%;
}
@media screen and (max-width: 670px) {
  .col-11 {
    width: 100%;
  }
}
.col-12 {
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-12 {
    width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .col-md-1 {
    width: 8.33%;
  }
  .col-md-2 {
    width: 16.66%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.33%;
  }
  .col-md-5 {
    width: 41.66%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.33%;
  }
  .col-md-8 {
    width: 66.66%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.33%;
  }
  .col-md-12 {
    width: 100%;
  }
}
@media screen and (max-width: 670px) {
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-12 {
    width: 100%;
  }
}
.burger {
  display: none;
}
@media screen and (max-width: 960px) {
  .burger {
    width: 30px;
    height: 30px;
    display: block;
    background: url(../images/burger.svg) no-repeat center, #ffffff;
    background-size: 13px 10px;
  }
  .burger--open {
    background: url(../images/burger-close.svg) no-repeat center, #ffffff;
    background-size: 13px 13px;
  }
}
.fansybox-photo-modal .fancybox-close {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 30px;
  right: 30px;
  background: url(../images/close-modal-photo.svg) no-repeat;
  cursor: pointer;
  transition: all 0.4s;
  z-index: 9999;
  background-size: contain;
}
@media screen and (max-width: 670px) {
  .fansybox-photo-modal .fancybox-close {
    top: 5px;
    right: 5px;
  }
}
.fansybox-photo-modal .fancybox-close:hover {
  transform: rotate(180deg);
}
.fansybox-photo-modal .fancybox-next span,
.fansybox-photo-modal .fancybox-prev span {
  display: block;
  width: 40px;
  height: 40px;
  /*border: 1px solid #ffffff;*/
  position: absolute;
  opacity: 1;
  visibility: visible;
}
.fansybox-photo-modal .fancybox-next span svg,
.fansybox-photo-modal .fancybox-prev span svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  fill: #ffffff;
  fill-rule: evenodd;
  width: 30px;
  height: 30px;
}
.fansybox-photo-modal .fancybox-prev span {
  left: 10px;
}
.fansybox-photo-modal .fancybox-prev span svg {
  transform: rotate(180deg);
}
.fansybox-photo-modal .fancybox-prev span svg:hover {
  animation: photo-fansy-nav-left 0.3s 3;
}
.fansybox-photo-modal .fancybox-next span {
  right: 10px;
}
.fansybox-photo-modal .fancybox-next span svg:hover {
  animation: photo-fansy-nav-right 0.3s 3;
}
@keyframes photo-fansy-nav-left {
  0% {
    left: 0;
  }
  25% {
    left: -5px;
  }
  50% {
    left: 0;
  }
  75% {
    left: 5px;
  }
  100% {
    left: 0;
  }
}
@keyframes photo-fansy-nav-right {
  0% {
    right: 0;
  }
  25% {
    right: -5px;
  }
  50% {
    right: 0;
  }
  75% {
    right: 5px;
  }
  100% {
    right: 0;
  }
}
.top-line {
  background-color: #0068a0;
}
@media screen and (max-width: 960px) {
  .top-line {
    display: none;
  }
}
.top-line__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0;
  align-content: center;
  align-items: center;
}
.top-line__main {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
.top-line__item {
  color: #ffffff;
  font-size: 14px;
}
.top-line__item--venue {
  padding: 0 40px 0 23px;
  position: relative;
}
.top-line__item--venue:before {
  content: '';
  display: block;
  width: 15px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../images/venue-icon-blue.svg) no-repeat center;
  background-size: contain;
}
.top-line__item--mail {
  padding: 0 45px 0 28px;
  position: relative;
  text-decoration: none;
}
.top-line__item--mail:before {
  content: '';
  display: block;
  width: 19px;
  height: 14px;
  position: absolute;
  top: 4px;
  left: 0;
  background: url(../images/mail-icon-blue.svg) no-repeat center;
  background-size: contain;
}
.top-line__contact-btn {
  font-family: 'RussoOne', sans-serif;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #76beea;
  position: relative;
  text-decoration: none;
  padding-right: 28px;
}
.top-line__contact-btn:after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
  background: url(../images/arrow-top-contact-white.svg) no-repeat center, #76beea;
}
.top-line__contact-btn--text {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
}
.top-line__contact-btn--text:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #76beea;
  position: absolute;
  bottom: 0;
  left: 0;
}
.search {
  position: relative;
}
.search__btn-for-search {
  display: block;
  width: 18px;
  height: 18px;
  background: url(../images/search-icon-white.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  right: 0;
}
.search__form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;
}
.search__form--open {
  opacity: 1;
  pointer-events: auto;
}
.search__input {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #ffffff;
  width: 200px;
  background-color: transparent;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.search__input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 0.6;
}
.search__input::-moz-placeholder {
  color: #ffffff;
  opacity: 0.6;
}
.search__input:-moz-placeholder {
  color: #ffffff;
  opacity: 0.6;
}
.search__input:-ms-input-placeholder {
  color: #ffffff;
  opacity: 0.6;
}
.search__input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.search__input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.search__input::-webkit-search-decoration,
.search__input::-webkit-search-cancel-button,
.search__input::-webkit-search-results-button,
.search__input::-webkit-search-results-decoration {
  display: none;
}
.search__search-btn {
  width: 18px;
  height: 18px;
  background: url(../images/search-icon-white.svg) no-repeat center;
  background-size: contain;
}
.top-info {
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
  position: relative;
}
@media screen and (max-width: 960px) {
  .top-info {
    box-shadow: none;
    background: #0068a0;
  }
}
.top-info__container,
.top-info__left,
.top-info__right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.top-info__left {
  justify-content: flex-start;
}
@media screen and (max-width: 960px) {
  .top-info__left {
    max-width: 40%;
  }
}
.top-info__right {
  justify-content: flex-end;
}
@media screen and (max-width: 960px) {
  .top-info__right {
    max-width: 60%;
  }
}
.top-info__logo {
  margin-right: 75px;
}
@media screen and (max-width: 1600px) {
  .top-info__logo {
    margin-right: 30px;
  }
}
@media screen and (max-width: 1100px) {
  .top-info__logo {
    max-width: 130px;
  }
}
@media screen and (max-width: 960px) {
  .top-info__logo {
    margin-right: 15px;
    padding-top: 5px;
    padding-bottom: 10px;
    max-width: 100%;
  }
}
.top-info__logo--white {
  display: none;
}
@media screen and (max-width: 960px) {
  .top-info__logo--white {
    display: block;
  }
}
@media screen and (max-width: 960px) {
  .top-info__logo--black {
    display: none;
  }
}
.top-info__main-data {
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
}
@media screen and (max-width: 1280px) {
  .top-info__main-data {
    margin-right: 0;
  }
}
@media screen and (max-width: 960px) {
  .top-info__main-data {
    margin-right: 10px;
  }
}
.top-info__phone {
  font-size: 22px;
  font-weight: 500;
  padding-left: 23px;
  position: relative;
  text-decoration: none;
  color: #1e1e1e;
}
@media screen and (max-width: 1600px) {
  .top-info__phone {
    font-size: 18px;
  }
}
@media screen and (max-width: 960px) {
  .top-info__phone {
    color: #ffffff;
    font-size: 14px;
    padding-left: 13px;
  }
}
.top-info__phone:before {
  content: '';
  display: block;
  width: 11px;
  height: 20px;
  background: url(../images/phone-icon-blue.png);
  background-size: contain;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
@media screen and (max-width: 960px) {
  .top-info__phone:before {
    width: 7px;
    height: 12px;
  }
}
.top-info__city {
  font-size: 13px;
}
@media screen and (max-width: 960px) {
  .top-info__city {
    color: #ffffff;
  }
}
@media screen and (max-width: 1280px) {
  .top-info__btn-block {
    display: none;
  }
}
.top-info__btn {
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #76beea;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  border: 2px solid #76beea;
  padding: 11px 28px 9px;
  font-family: 'RussoOne', sans-serif;
  transition: all 0.5s;
}
@media screen and (max-width: 1600px) {
  .top-info__btn {
    padding: 11px 15px 9px;
  }
}
.top-info__btn:hover {
  color: #ffffff;
  background: #76beea;
}
@media screen and (min-width: 961px) {
  .top-info--opacity {
    background: none;
    box-shadow: none;
  }
  .top-info--opacity .top-info__logo--black {
    display: none;
  }
  .top-info--opacity .top-info__logo--white {
    display: block;
  }
  .top-info--opacity .menu__link {
    color: #ffffff;
  }
  .top-info--opacity .menu__link--active,
  .top-info--opacity .menu__item:hover .menu__link {
    color: #76beea !important;
    border-color: #76beea !important;
  }
  .top-info--opacity .top-info__phone {
    color: #ffffff;
  }
  .top-info--opacity .top-info__city {
    color: #ffffff;
  }
}
@media screen and (max-width: 960px) {
  .menu {
    display: none;
  }
  .menu--open {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #0a3a53;
    height: calc(100vh - 50px);
    overflow: auto;
  }
}
.menu__list {
  display: flex;
  list-style: none;
}
@media screen and (max-width: 960px) {
  .menu__list {
    flex-direction: column;
  }
}
.menu__item {
  margin-right: 35px;
  padding-top: 33px;
  padding-bottom: 35px;
  position: relative;
}
@media screen and (max-width: 1600px) {
  .menu__item {
    margin-right: 20px;
  }
}
@media screen and (min-width: 961px) {
  .menu__item:hover .menu__link {
    color: #0068a0;
    border-bottom: 2px solid #0068a0;
  }
  .menu__item:hover .submenu {
    display: block;
  }
}
@media screen and (max-width: 960px) {
  .menu__item {
    margin: 0;
    padding: 0;
  }
}
.menu__link {
  font-size: 13px;
  font-family: 'RussoOne', sans-serif;
  color: #1e1e1e;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1em;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  cursor: pointer;
}
@media screen and (max-width: 1100px) {
  .menu__link {
    font-size: 11px;
  }
}
@media screen and (max-width: 960px) {
  .menu__link {
    background-color: #0a3a53;
    font-size: 15px;
    color: #ffffff;
    width: 100%;
    padding: 10px 15px;
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .menu__link:after {
    content: '';
    display: block;
    width: 11px;
    height: 9px;
    position: absolute;
    top: 17px;
    right: 20px;
    background: url(../images/menu-arrow.svg) no-repeat;
    background-size: contain;
  }
  .menu__link--hasSub:after {
    width: 9px;
    height: 11px;
    background-image: url(../images/menu-arrow-down.svg);
    top: 13px;
  }
}
@media screen and (min-width: 961px) {
  .menu__link:hover,
  .menu__link--active,
  .menu__link--open {
    color: #0068a0;
    border-bottom: 2px solid #0068a0;
  }
}
@media screen and (max-width: 960px) {
  .menu__link--active,
  .menu__link--open {
    color: #4886aa;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .menu__link--active:after,
  .menu__link--open:after {
    background-image: url(../images/menu-arrow-top.svg);
  }
}
.submenu {
  display: none;
  position: absolute;
  left: -30px;
  top: 100%;
  list-style: none;
}
@media screen and (max-width: 960px) {
  .submenu {
    position: relative;
    left: auto;
    top: auto;
  }
  .submenu--open {
    display: block;
  }
}
.submenu__item {
  background-color: #0068a0;
  border-bottom: 1px solid #3386b3;
  position: relative;
}
.submenu__item:last-child {
  border-bottom: 0;
}
.submenu__link {
  font-size: 15px;
  color: #ffffff;
  text-decoration: none;
  padding: 10px 35px 10px 30px;
  display: block;
  position: relative;
  width: 350px;
}
@media screen and (max-width: 960px) {
  .submenu__link {
    font-size: 14px;
    width: 100%;
  }
}
.submenu__link:after {
  content: '';
  display: block;
  width: 11px;
  height: 9px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 20px;
  background: url(../images/menu-arrow.svg);
}
.submenu__link:hover,
.submenu__link--active,
.submenu__link--open {
  background-color: #76beea;
}
.submenu__submenu {
  display: none;
}
@media screen and (max-width: 960px) {
  .submenu__submenu {
    position: relative;
    left: auto;
    top: auto;
    padding-left: 50px;
    list-style: none;
  }
  .submenu__submenu--open {
    display: block;
  }
  .submenu__submenu .submenu__link {
    background: transparent;
  }
  .submenu__submenu .submenu__link--active,
  .submenu__submenu .submenu__link--open {
    background-color: #76beea;
  }
}
@media screen and (min-width: 961px) {
  .submenu__item:hover .submenu__submenu {
    display: block;
    position: absolute;
    left: 100%;
    top: 0;
    list-style: none;
  }
}
.promo-slider-block {
  margin-top: -90px;
  height: 800px;
}
@media screen and (max-width: 1280px) {
  .promo-slider-block {
    height: 600px;
  }
}
@media screen and (max-width: 960px) {
  .promo-slider-block {
    margin-top: 0;
  }
}
@media screen and (max-width: 670px) {
  .promo-slider-block {
    height: auto;
  }
}
.promo-slider__item {
  position: relative;
  display: block;
}
.promo-slider__container {
  width: 100%;
  max-width: 1340px;
  padding: 0 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: 21%;
  bottom: 18%;
  margin: auto;
}
@media screen and (max-width: 1280px) {
  .promo-slider__container {
    top: 19%;
    bottom: 10%;
  }
}
@media screen and (max-width: 960px) {
  .promo-slider__container {
    top: 10%;
  }
}
@media screen and (max-width: 670px) {
  .promo-slider__container {
    padding: 0 15px;
    top: 40px;
    bottom: 8%;
  }
}
.promo-slider__info {
  width: 640px;
  max-width: 100%;
  position: relative;
  height: 100%;
}
.promo-slider__title {
  font-family: 'RussoOne', sans-serif;
  font-weight: 400;
  font-size: 35px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}
@media screen and (max-width: 1280px) {
  .promo-slider__title {
    font-size: 30px;
  }
}
@media screen and (max-width: 670px) {
  .promo-slider__title {
    font-size: 18px;
  }
}
.promo-slider__object {
  width: 324px;
  min-height: 274px;
  border-top: 4px solid #ffffff;
  border-bottom: 4px solid #ffffff;
  border-right: 4px solid #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media screen and (max-width: 670px) {
  .promo-slider__object {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 35px;
    width: 200px;
    min-height: 160px;
    border-width: 2px;
    margin-left: 30px;
  }
}
.promo-slider__object:before {
  content: '';
  display: block;
  width: 4px;
  height: 100%;
  background-image: linear-gradient(to bottom, #ffffff 30px, rgba(255, 255, 255, 0) 30px, rgba(255, 255, 255, 0) 100px, #ffffff 100px, #ffffff 100%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 670px) {
  .promo-slider__object:before {
    width: 2px;
    background-image: linear-gradient(to bottom, #ffffff 15px, rgba(255, 255, 255, 0) 15px, rgba(255, 255, 255, 0) 50px, #ffffff 50px, #ffffff 100%);
  }
}
.promo-slider__object-more {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -4px;
  right: -4px;
  background: url(../images/arrow-more-info.svg) no-repeat center, #ffffff;
  z-index: 5;
}
@media screen and (max-width: 670px) {
  .promo-slider__object-more {
    width: 20px;
    height: 20px;
    bottom: -2px;
    right: -2px;
    background-size: 5px 5px;
  }
}
.promo-slider__object-title {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 40px;
  position: absolute;
  top: 35px;
  right: 50%;
  color: #ffffff;
  width: max-content;
  text-transform: none;
}
@media screen and (max-width: 670px) {
  .promo-slider__object-title {
    top: 15px;
    left: -32px;
    font-size: 20px;
  }
}
.promo-slider__object-text {
  padding: 110px 30px 30px 30px;
  font-size: 17px;
  line-height: 1.6;
  color: #ffffff;
}
@media screen and (max-width: 670px) {
  .promo-slider__object-text {
    font-size: 12px;
    padding: 60px 10px 15px 10px;
  }
}
.promo-slider__img-bg {
  height: 800px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1280px) {
  .promo-slider__img-bg {
    height: 600px;
  }
}
@media screen and (max-width: 670px) {
  .promo-slider__img-bg {
    width: 100% !important;
    max-height: 450px;
  }
  .promo-slider__img-bg img {
    max-height: 450px;
  }
}
.promo-slider.owl-carousel .owl-item .promo-slider__img-bg--desc {
  width: auto;
  height: 100%;
  max-width: initial;
}
@media screen and (max-width: 670px) {
  .promo-slider.owl-carousel .owl-item .promo-slider__img-bg--desc {
    display: none;
  }
}
.promo-slider.owl-carousel .owl-item .promo-slider__img-bg--mob {
  display: none;
}
@media screen and (max-width: 670px) {
  .promo-slider.owl-carousel .owl-item .promo-slider__img-bg--mob {
    display: block;
  }
}
.promo-slider.owl-carousel .owl-dots {
  position: absolute;
  bottom: 18%;
  width: 100%;
  max-width: 1340px;
  padding: 0 30px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  pointer-events: none;
}
@media screen and (max-width: 1280px) {
  .promo-slider.owl-carousel .owl-dots {
    bottom: 10%;
  }
}
@media screen and (max-width: 670px) {
  .promo-slider.owl-carousel .owl-dots {
    bottom: 8%;
    padding: 0 15px;
  }
}
.promo-slider.owl-carousel .owl-dot {
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-right: 10px;
  position: relative;
  pointer-events: auto;
}
@media screen and (max-width: 670px) {
  .promo-slider.owl-carousel .owl-dot {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}
.promo-slider.owl-carousel .owl-dot:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.3);
}
@media screen and (max-width: 670px) {
  .promo-slider.owl-carousel .owl-dot:before {
    font-size: 10px;
    line-height: 20px;
  }
}
.promo-slider.owl-carousel .owl-dot:nth-child(1):before {
  content: '1';
}
.promo-slider.owl-carousel .owl-dot:nth-child(2):before {
  content: '2';
}
.promo-slider.owl-carousel .owl-dot:nth-child(3):before {
  content: '3';
}
.promo-slider.owl-carousel .owl-dot:nth-child(4):before {
  content: '4';
}
.promo-slider.owl-carousel .owl-dot:nth-child(5):before {
  content: '5';
}
.promo-slider.owl-carousel .owl-dot:nth-child(6):before {
  content: '6';
}
.promo-slider.owl-carousel .owl-dot:nth-child(7):before {
  content: '7';
}
.promo-slider.owl-carousel .owl-dot:nth-child(8):before {
  content: '8';
}
.promo-slider.owl-carousel .owl-dot:nth-child(9):before {
  content: '9';
}
.promo-slider.owl-carousel .owl-dot:nth-child(10):before {
  content: '10';
}
.promo-slider.owl-carousel .owl-dot.active {
  background-color: #0068a0;
  border-color: #0068a0;
}
.promo-slider.owl-carousel .owl-dot.active:before {
  color: #ffffff;
}
.promo-video {
  margin-top: -50px;
  margin-bottom: 90px;
  position: relative;
  z-index: 5;
}
@media screen and (max-width: 960px) {
  .promo-video {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 670px) {
  .promo-video {
    margin-bottom: 25px;
  }
}
.promo-video__row {
  align-content: flex-end;
  align-items: flex-end;
}
@media screen and (max-width: 960px) {
  .promo-video__row {
    align-content: flex-start;
    align-items: flex-start;
  }
}
@media screen and (max-width: 670px) {
  .promo-video__video {
    order: 1;
    margin-top: 30px;
  }
}
.promo-video__info {
  padding-top: 70px;
  padding-left: 70px;
}
@media screen and (max-width: 1280px) {
  .promo-video__info {
    padding-left: 35px;
  }
}
@media screen and (max-width: 960px) {
  .promo-video__info {
    padding-top: 0;
  }
}
@media screen and (max-width: 670px) {
  .promo-video__info {
    padding-left: 0;
  }
}
.promo-video__title {
  font-family: 'RussoOne', sans-serif;
  font-size: 25px;
  letter-spacing: 0.1em;
  color: #1e1e1e;
  text-transform: uppercase;
  margin-bottom: 40px;
}
@media screen and (max-width: 960px) {
  .promo-video__title {
    margin-bottom: 20px;
    font-size: 21px;
  }
}
@media screen and (max-width: 670px) {
  .promo-video__title {
    font-size: 16px;
  }
}
@media screen and (max-width: 960px) {
  .promo-video__text {
    font-size: 14px;
  }
}
.promo-video__text p {
  margin-bottom: 20px;
}
@media screen and (max-width: 960px) {
  .promo-video__text p {
    margin-bottom: 10px;
  }
}
.promo-video__text p:last-child {
  margin-bottom: 0;
}
.video-youtube {
  position: relative;
  padding-bottom: 56.25%;
  /* 56.25% задаёт высоту контейнера для 16:9 (если 4:3 -- поставьте 75%) */
  height: 0;
  overflow: hidden;
}
.video-youtube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-youtube__img {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.video-youtube__img:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(https://www.admiralpools.ru/wp-content/themes/admiral/img/play-white-small.png) no-repeat center;
}
.video-youtube__img img {
  width: 100%;
}
.video-youtube__img--hide {
  display: none;
}
.promo-numbers {
  position: relative;
  padding-bottom: 50px;
}
@media screen and (max-width: 670px) {
  .promo-numbers {
    background: url(../images/map-bg.png) no-repeat top right;
    background-size: 292px 163px;
    padding-bottom: 20px;
  }
}
.promo-numbers:before {
  content: '';
  display: block;
  position: absolute;
  bottom: -20px;
  right: 100px;
  background: url(../images/numbers-bg.png) no-repeat bottom right;
  background-size: 501px 753px;
  width: 501px;
  height: 753px;
  z-index: 4;
}
@media screen and (max-width: 1280px) {
  .promo-numbers:before {
    display: none;
  }
}
.promo-numbers__main {
  min-height: 313px;
  background: url(../images/map-bg.png) no-repeat 43px 19px;
  background-size: 527px 293px;
}
@media screen and (max-width: 1280px) {
  .promo-numbers__main {
    background-size: contain;
    background-position: top right;
  }
}
@media screen and (max-width: 670px) {
  .promo-numbers__main {
    background: none;
    min-height: initial;
  }
}
.promo-numbers__title {
  font-size: 35px;
  font-family: 'RussoOne', sans-serif;
  letter-spacing: 0.1em;
  color: #1e1e1e;
  padding-top: 15px;
}
@media screen and (max-width: 960px) {
  .promo-numbers__title {
    font-size: 30px;
  }
}
@media screen and (max-width: 670px) {
  .promo-numbers__title {
    font-size: 18px;
    padding-bottom: 10px;
  }
}
.promo-numbers__num-block {
  padding-top: 15px;
}
.promo-numbers__item {
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 5;
}
.promo-numbers__num {
  font-weight: 700;
  color: #0068a0;
  font-size: 35px;
}
@media screen and (max-width: 670px) {
  .promo-numbers__num {
    font-size: 21px;
  }
}
.promo-numbers__num-text {
  color: #1e1e1e;
}
@media screen and (max-width: 960px) {
  .promo-numbers__num-text {
    font-size: 14px;
  }
}
.types-jobs {
  position: relative;
  z-index: 6;
  background-image: linear-gradient(to bottom, rgba(245, 245, 245, 0) 0%, #f5f5f5 100%);
}
.types-jobs__item {
  position: relative;
  display: block;
}
.types-jobs__bg img {
  display: block;
}
.types-jobs .col {
  margin-bottom: 14px;
}
.types-jobs__info {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.5s;
  background: rgba(0, 104, 160, 0);
  overflow: hidden;
}
.types-jobs__info:hover {
  background: rgba(0, 104, 160, 0.8);
}
.types-jobs__inner {
  border: 4px solid rgba(118, 190, 234, 0);
  width: 86.5%;
  height: 86.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.5s;
  padding: 20px;
  position: relative;
}
.types-jobs__inner:before {
  content: '';
  display: block;
  opacity: 0;
  transition: all 0.5s;
  width: 40px;
  height: 40px;
  position: absolute;
  right: -2px;
  top: -2px;
  background: #76beea;
}
.types-jobs__inner:after {
  content: '';
  display: block;
  opacity: 0;
  transition: all 0.7s;
  width: 11px;
  height: 11px;
  background: url(../images/arrow-more-info-white.svg) no-repeat;
  position: absolute;
  top: 12px;
  right: 12px;
}
.types-jobs__title {
  font-size: 22px;
  color: #ffffff;
  text-transform: uppercase;
  transition: all 0.5s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}
@media screen and (max-width: 960px) {
  .types-jobs__title {
    font-size: 16px;
  }
}
@media screen and (max-width: 670px) {
  .types-jobs__title {
    font-size: 11px;
  }
}
.types-jobs__text {
  transition: all 0.5s;
  color: #ffffff;
  margin-top: 25px;
  opacity: 0;
  transform: translateY(100px);
  width: 100%;
}
@media screen and (min-width: 961px) {
  .types-jobs__info:hover .types-jobs__inner {
    border-color: #76beea;
  }
  .types-jobs__info:hover .types-jobs__inner:before,
  .types-jobs__info:hover .types-jobs__inner:after {
    opacity: 1;
    transition: all 0.5s;
  }
  .types-jobs__info:hover .types-jobs__inner:after {
    transition: all 1.5s;
  }
  .types-jobs__info:hover .types-jobs__title {
    top: auto;
    transform: translateY(0);
    position: relative;
    left: auto;
    right: auto;
    margin: 0;
  }
  .types-jobs__info:hover .types-jobs__text {
    opacity: 1;
    transform: translateY(0px);
  }
}
.our-projects {
  padding-top: 70px;
  padding-bottom: 120px;
  background: #f5f5f5;
}
@media screen and (max-width: 670px) {
  .our-projects {
    padding-top: 30px;
    padding-bottom: 35px;
  }
}
.our-projects__title-block {
  display: flex;
  justify-content: space-between;
  color: #1e1e1e;
  align-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 40px;
}
@media screen and (max-width: 670px) {
  .our-projects__title-block {
    display: block;
    margin-bottom: 25px;
  }
}
.our-projects__btn-all {
  font-family: 'RussoOne', sans-serif;
  font-size: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #0068a0;
  position: relative;
  text-decoration: none;
  padding-right: 45px;
  transition: all 0.5s;
}
@media screen and (max-width: 670px) {
  .our-projects__btn-all {
    display: none;
    font-size: 11px;
    padding-right: 25px;
  }
}
.our-projects__btn-all:after {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  top: -3px;
  background: url(../images/arrow-top-contact-white.svg) no-repeat center, #76beea;
  background-size: 11px 11px;
}
@media screen and (max-width: 670px) {
  .our-projects__btn-all:after {
    width: 15px;
    height: 15px;
    background-size: 5px 5px;
    top: 0;
  }
}
.our-projects__btn-all:hover {
  opacity: 0.8;
}
.our-projects__btn-all--text {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
}
@media screen and (max-width: 670px) {
  .our-projects__btn-all--text {
    padding-bottom: 2px;
  }
}
.our-projects__btn-all--text:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #0068a0;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 670px) {
  .our-projects__btn-all--text:after {
    height: 1px;
  }
}
.our-projects__btn-all--mob {
  display: none;
}
@media screen and (max-width: 670px) {
  .our-projects__btn-all--mob {
    display: inline-block;
    margin-top: 15px;
  }
}
.our-projects-slider__item {
  display: block;
  position: relative;
}
.our-projects-slider__text {
  background: #ffffff;
  padding: 23px 25px 28px;
}
@media screen and (max-width: 670px) {
  .our-projects-slider__text {
    padding: 10px 7px 12px;
  }
}
.our-projects-slider__direction {
  position: relative;
  z-index: 2;
  display: block;
  font-size: 14px;
  color: #0068a0;
  text-decoration: none;
}
@media screen and (max-width: 670px) {
  .our-projects-slider__direction {
    font-size: 12px;
  }
}
.our-projects-slider__title {
  font-size: 20px;
  font-weight: 500;
  color: #1e1e1e;
  min-height: 65px;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media screen and (max-width: 670px) {
  .our-projects-slider__title {
    font-size: 14px;
    margin-top: 5px;
    min-height: 35px;
  }
}
.our-projects-slider__title a {
  color: #1e1e1e;
  text-decoration: none;
}
.our-projects-slider__venue {
  font-size: 15px;
  padding-left: 27px;
  position: relative;
  margin-bottom: 17px;
}
@media screen and (max-width: 670px) {
  .our-projects-slider__venue {
    padding-left: 10px;
    font-size: 11px;
    margin-bottom: 7px;
  }
}
.our-projects-slider__venue:before {
  content: '';
  display: block;
  width: 15px;
  height: 18px;
  background: url(../images/icon-venue-blue.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 2px;
}
@media screen and (max-width: 670px) {
  .our-projects-slider__venue:before {
    width: 7px;
    height: 9px;
  }
}
.our-projects-slider__tags {
  display: block;
}
.our-projects-slider__tag {
  position: relative;
  z-index: 2;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  color: #1e1e1e;
  text-decoration: none;
  border-radius: 20px;
  background: #d6ebf9;
  padding: 3px 13px;
}
@media screen and (max-width: 670px) {
  .our-projects-slider__tag {
    font-size: 11px;
    padding: 1px 5px;
  }
}
.our-projects-slider.owl-carousel .owl-item {
  opacity: 0.3;
  position: relative;
}
@media screen and (max-width: 670px) {
  .our-projects-slider.owl-carousel .owl-item {
    opacity: 1;
  }
  .our-projects-slider.owl-carousel .owl-item:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 104, 160, 0.5);
    position: absolute;
    z-index: 2;
    transition: all 0.5s;
  }
}
.our-projects-slider.owl-carousel .owl-item.active {
  opacity: 1;
}
@media screen and (max-width: 670px) {
  .our-projects-slider.owl-carousel .owl-item.active:before {
    opacity: 0;
    pointer-events: none;
  }
}
.our-projects-slider.owl-carousel .owl-stage-outer {
  overflow: visible;
}
.our-projects-slider.owl-carousel .owl-nav {
  position: absolute;
  width: calc((100vw - 1310px)/2);
  height: 100%;
  display: block;
  right: calc((100vw - 1310px + 30px)/2 * -1);
  top: 0;
  bottom: 0;
}
@media screen and (max-width: 1440px) {
  .our-projects-slider.owl-carousel .owl-nav {
    width: calc(20vw/2 + 30px);
    right: 0;
  }
}
@media screen and (max-width: 670px) {
  .our-projects-slider.owl-carousel .owl-nav {
    width: 30px;
    right: -40px;
  }
}
.our-projects-slider.owl-carousel .owl-nav .owl-next {
  width: 100%;
  height: 100%;
  background: none;
  position: relative;
}
.our-projects-slider.owl-carousel .owl-nav .owl-next:before {
  content: '';
  display: block;
  background: url(../images/arrow-right-black.svg) no-repeat center, #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 130px;
}
@media screen and (max-width: 670px) {
  .our-projects-slider.owl-carousel .owl-nav .owl-next:before {
    display: none;
  }
}
.tupes-block {
  padding-top: 120px;
  padding-bottom: 100px;
  background: url(../images/tupes-block-bg.jpg) no-repeat;
  background-size: cover;
  color: #ffffff;
}
@media screen and (max-width: 960px) {
  .tupes-block {
    padding-top: 60px;
    padding-bottom: 41px;
  }
}
@media screen and (max-width: 670px) {
  .tupes-block {
    padding-top: 30px;
    padding-bottom: 31px;
  }
}
.tupes-block__col-small {
  width: 25%;
}
@media screen and (max-width: 960px) {
  .tupes-block__col-small {
    width: 100%;
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 670px) {
  .tupes-block__col-small {
    width: 100%;
    margin-bottom: 13px;
  }
}
.tupes-block__col-large {
  width: 37.5%;
  padding-top: 5px;
}
@media screen and (max-width: 960px) {
  .tupes-block__col-large {
    width: 100%;
    margin-bottom: 0;
    padding-top: 0;
  }
}
@media screen and (max-width: 670px) {
  .tupes-block__col-large {
    width: 100%;
  }
}
.tupes-block__title {
  text-transform: uppercase;
  max-width: 260px;
  margin-bottom: 35px;
  line-height: 1.6;
  color: #ffffff;
}
@media screen and (max-width: 960px) {
  .tupes-block__title {
    max-width: 100%;
  }
}
@media screen and (max-width: 670px) {
  .tupes-block__title {
    margin-bottom: 15px;
  }
}
.tupes-block__list {
  list-style: none;
}
.tupes-block__list li {
  padding-left: 27px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
@media screen and (max-width: 670px) {
  .tupes-block__list li {
    margin-bottom: 5px;
    font-size: 14px;
  }
}
.tupes-block__list li:before {
  content: '';
  display: block;
  width: 16px;
  height: 12px;
  background: url(../images/list-li.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 5px;
}
@media screen and (max-width: 670px) {
  .tupes-block__list li:before {
    width: 8px;
    height: 6px;
  }
}
.tupes-block__p {
  margin-bottom: 24px;
  line-height: 1.6;
}
@media screen and (max-width: 670px) {
  .tupes-block__p {
    margin-bottom: 13px;
    font-size: 14px;
  }
}
.tupes-block__subtitle {
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 24px;
  line-height: 1.6;
  color: #ffffff;
  text-transform: none;
}
@media screen and (max-width: 670px) {
  .tupes-block__subtitle {
    margin-bottom: 13px;
    font-size: 16px;
  }
}
.trus-us {
  margin-top: 135px;
  margin-bottom: 135px;
}
@media screen and (max-width: 960px) {
  .trus-us {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
.trus-us__info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
@media screen and (max-width: 960px) {
  .trus-us__info {
    display: block;
  }
}
.trus-us__title-block {
  width: 215px;
}
@media screen and (max-width: 960px) {
  .trus-us__title-block {
    width: 100%;
    margin-bottom: 30px;
  }
}
.trus-us__slider {
  width: calc(100% - 215px);
}
@media screen and (max-width: 960px) {
  .trus-us__slider {
    width: 100%;
  }
}
.trus-us__title {
  color: #1e1e1e;
  text-transform: uppercase;
}
@media screen and (max-width: 670px) {
  .trus-us__inner {
    padding-right: 0;
  }
}
.trus-us-slider__item {
  display: block;
  width: 100%;
  border: 1px solid #e6e6e6;
  height: 0;
  padding-bottom: 55.5%;
}
@media screen and (max-width: 670px) {
  .trus-us-slider__item {
    width: 25vw;
    margin-right: -1px;
  }
}
.trus-us-slider__img {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.trus-us-slider.owl-carousel .owl-item .trus-us-slider__img {
  width: auto;
  max-width: 90%;
  max-height: 90%;
}
.trus-us-slider.owl-carousel .owl-nav {
  position: absolute;
  right: -100px;
  width: 100px;
  height: 100%;
  top: 0;
  bottom: 0;
}
@media screen and (max-width: 1500px) {
  .trus-us-slider.owl-carousel .owl-nav {
    right: -30px;
  }
}
@media screen and (max-width: 670px) {
  .trus-us-slider.owl-carousel .owl-nav {
    right: 0;
  }
}
.trus-us-slider.owl-carousel .owl-nav .owl-next {
  width: 100%;
  height: 100%;
  background: none;
  position: relative;
}
@media screen and (max-width: 670px) {
  .trus-us-slider.owl-carousel .owl-nav .owl-next {
    width: calc(30vw/3);
    position: absolute;
    right: 0;
  }
}
.trus-us-slider.owl-carousel .owl-nav .owl-next:before {
  content: '';
  display: block;
  background: url(../images/arrow-right-black.svg) no-repeat center, #d6ebf9;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}
@media screen and (max-width: 670px) {
  .trus-us-slider.owl-carousel .owl-nav .owl-next:before {
    width: calc(30vw/3);
    background-size: 10px 8px;
    border-radius: 0;
    height: 100%;
    right: 0;
  }
}
@media screen and (min-width: 961px) {
  .trus-us-slider__img {
    filter: grayscale(80%);
    -webkit-filter: grayscale(80%);
    -moz-filter: grayscale(80%);
    -ms-filter: grayscale(80%);
    -o-filter: grayscale(80%);
    filter: url(desaturate.svg#greyscale);
    filter: gray;
    -webkit-filter: grayscale(1);
  }
  .trus-us-slider__item:hover .trus-us-slider__img {
    filter: none;
  }
  .trus-us-slider__item {
    border: 1px solid rgba(230, 230, 230, 0.6);
  }
  .trus-us-slider__item:hover {
    border: 1px solid #e6e6e6;
  }
}
.footer {
  padding-top: 45px;
  padding-bottom: 45px;
  background: #1e1e1e;
}
@media screen and (max-width: 670px) {
  .footer {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.footer__main {
  margin-bottom: 70px;
  overflow: hidden;
}
@media screen and (max-width: 960px) {
  .footer__main {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 670px) {
  .footer__main {
    margin-bottom: 35px;
  }
}
.footer__item {
  display: flex;
  flex-direction: column;
}
.footer__logo {
  margin-bottom: 30px;
}
@media screen and (max-width: 670px) {
  .footer__logo {
    max-width: 85%;
    margin-bottom: 15px;
  }
}
.footer__phone {
  font-size: 25px;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 15px;
}
@media screen and (max-width: 670px) {
  .footer__phone {
    font-size: 14px;
    margin-bottom: 7px;
  }
}
.footer__email {
  font-size: 14px;
  color: #0068a0;
  text-decoration: none;
  margin-bottom: 15px;
}
@media screen and (max-width: 670px) {
  .footer__email {
    margin-bottom: 7px;
  }
}
.footer__venue {
  font-size: 14px;
  line-height: 1.7;
  color: #ffffff;
  margin-bottom: 15px;
}
@media screen and (max-width: 670px) {
  .footer__venue {
    margin-bottom: 7px;
  }
}
.footer__title {
  margin-bottom: 45px;
  font-size: 16px;
  font-family: 'RussoOne', sans-serif;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 25px;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 960px) {
  .footer__title {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 670px) {
  .footer__title {
    margin-bottom: 15px;
    margin-top: 20px;
    font-size: 12px;
  }
}
.footer__another {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.2);
}
@media screen and (max-width: 670px) {
  .footer__another {
    font-size: 11px;
  }
}
.footer__another a {
  color: rgba(255, 255, 255, 0.2);
  text-decoration: none;
}
.footer__another a:hover {
  color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 960px) {
  .footer__col-jobs {
    order: 1;
  }
}
@media screen and (max-width: 670px) {
  .footer__col-jobs {
    order: 0;
  }
}
@media screen and (max-width: 670px) {
  .footer__col-company {
    margin-top: -110px;
  }
}
.social {
  display: flex;
  margin-top: 15px;
  align-content: center;
  align-items: center;
}
@media screen and (max-width: 960px) {
  .social {
    margin-bottom: 30px;
  }
}
.social__item {
  margin-right: 20px;
  fill: #ffffff;
  display: flex;
}
@media screen and (max-width: 670px) {
  .social__item {
    margin-right: 10px;
  }
}
.social__item:hover {
  fill: #76beea;
}
.social__item--inst svg {
  width: 19px;
  height: 18px;
}
@media screen and (max-width: 670px) {
  .social__item--inst svg {
    width: 15px;
    height: 15px;
  }
}
.social__item--vk svg {
  width: 23px;
  height: 13px;
}
@media screen and (max-width: 670px) {
  .social__item--vk svg {
    width: 19px;
    height: 10px;
  }
}
.social__item--fb svg {
  width: 11px;
  height: 18px;
}
@media screen and (max-width: 670px) {
  .social__item--fb svg {
    width: 9px;
    height: 15px;
  }
}
.footer-menu {
  list-style: none;
}
.footer-menu__link {
  font-size: 15px;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 17px;
  display: inline-block;
}
@media screen and (max-width: 960px) {
  .footer-menu__link {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 670px) {
  .footer-menu__link {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
.footer-menu__link--active {
  color: #76beea;
}
@media screen and (min-width: 961px) {
  .footer-menu__link:hover {
    color: #76beea;
  }
}
.footer-menu__link--btn {
  font-family: 'RussoOne', sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 13px;
  color: #0068a0;
  padding-bottom: 5px;
  position: relative;
}
@media screen and (max-width: 670px) {
  .footer-menu__link--btn {
    font-size: 10px;
    margin-top: 3px;
  }
}
.footer-menu__link--btn:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: #0068a0;
  position: absolute;
  bottom: 0;
  left: 0;
}
.footer-menu__link--btn:hover:after {
  background: #76beea;
}
.about {
  background-color: #f6f5f5;
  padding-bottom: 100px;
}
@media screen and (max-width: 960px) {
  .about {
    padding-bottom: 50px;
  }
}
.about__info {
  margin-top: 70px;
}
@media screen and (max-width: 960px) {
  .about__info {
    margin-top: 15px;
  }
}
@media screen and (max-width: 670px) {
  .about__info {
    margin-top: 10px;
  }
}
.about__title {
  font-size: 23px;
  font-weight: 500;
  color: #1e1e1e;
  margin-bottom: 27px;
  margin-top: 10px;
}
@media screen and (max-width: 670px) {
  .about__title {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.about__text {
  line-height: 1.6;
  margin-bottom: 21px;
}
@media screen and (max-width: 670px) {
  .about__text {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.about__col-small {
  padding-right: 16px;
}
@media screen and (max-width: 670px) {
  .about__col-small {
    padding-right: 0;
  }
}
.about__stages {
  list-style: none;
}
.about__stage {
  font-size: 18px;
  color: #1e1e1e;
  margin-bottom: 25px;
  padding-left: 50px;
  position: relative;
  font-weight: 400;
}
@media screen and (max-width: 670px) {
  .about__stage {
    font-size: 14px;
    margin-bottom: 10px;
    padding-left: 25px;
  }
}
.about__stage:before {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 4px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.about__stage--projection:before {
  width: 28px;
  height: 20px;
  background-image: url(../images/about/projection.svg);
}
@media screen and (max-width: 670px) {
  .about__stage--projection:before {
    width: 14px;
    height: 10px;
  }
}
.about__stage--expertise:before {
  width: 24px;
  height: 29px;
  background-image: url(../images/about/expertise-icon.svg);
}
@media screen and (max-width: 670px) {
  .about__stage--expertise:before {
    width: 12px;
    height: 15px;
  }
}
.about__stage--production:before {
  width: 28px;
  height: 28px;
  background-image: url(../images/about/production-icon.png);
}
@media screen and (max-width: 670px) {
  .about__stage--production:before {
    width: 14px;
    height: 14px;
  }
}
.about__stage--assembling:before {
  width: 23px;
  height: 23px;
  background-image: url(../images/about/assembling-icon.png);
}
@media screen and (max-width: 670px) {
  .about__stage--assembling:before {
    width: 12px;
    height: 12px;
  }
}
.about__stage--warranty:before {
  width: 23px;
  height: 25px;
  background-image: url(../images/about/warranty-icon.png);
}
@media screen and (max-width: 670px) {
  .about__stage--warranty:before {
    width: 12px;
    height: 13px;
  }
}
.about__img {
  margin-top: 10px;
}
@media screen and (max-width: 670px) {
  .about__img {
    margin-top: 20px;
  }
}
.about__img img {
  width: 100%;
}
.breadcrumbs {
  padding-top: 40px;
  margin-bottom: 40px;
}
@media screen and (max-width: 960px) {
  .breadcrumbs {
    padding-top: 20px;
    margin-bottom: 20px;
  }
}
.breadcrumbs__item {
  display: inline-block;
  color: #1e1e1e;
  font-size: 13px;
}
@media screen and (max-width: 670px) {
  .breadcrumbs__item {
    font-size: 12px;
  }
}
.breadcrumbs__link {
  color: #969696;
  text-decoration: none;
}
.breadcrumbs__link:after {
  content: '/';
  padding-left: 10px;
  padding-right: 7px;
}
.history {
  padding-top: 100px;
  padding-bottom: 120px;
}
@media screen and (max-width: 960px) {
  .history {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 670px) {
  .history {
    padding-top: 50px;
    padding-bottom: 25px;
  }
}
.history__title {
  text-align: center;
}
.history__text {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .history__text {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media screen and (max-width: 670px) {
  .history__text {
    font-size: 14px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.history__block {
  overflow: hidden;
}
.history__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -40px;
  margin-right: -40px;
  position: relative;
}
@media screen and (max-width: 960px) {
  .history__row {
    margin-left: -30px;
    margin-right: -30px;
  }
}
@media screen and (max-width: 670px) {
  .history__row {
    margin-left: 0;
    margin-right: 0;
  }
}
.history__col {
  padding-left: 40px;
  padding-right: 40px;
  width: 50%;
}
@media screen and (max-width: 960px) {
  .history__col {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 670px) {
  .history__col {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}
.history__col:nth-child(2n-1) {
  border-right: 1px solid #e6e6e6;
}
@media screen and (max-width: 670px) {
  .history__col:nth-child(2n-1) {
    border: 0;
  }
}
.history__col:nth-child(2n) {
  border-left: 1px solid #e6e6e6;
}
@media screen and (max-width: 670px) {
  .history__col:nth-child(2n) {
    border: 0;
  }
}
.history__item-img img {
  width: 100%;
  display: block;
}
.history__item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.history__name {
  font-size: 23px;
  color: #1e1e1e;
  font-weight: 500;
  margin-bottom: 30px;
  position: relative;
  margin-top: 20px;
}
@media screen and (max-width: 960px) {
  .history__name {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
  }
}
@media screen and (max-width: 670px) {
  .history__name {
    font-size: 16px;
  }
}
.history__name:before {
  content: '';
  display: block;
  width: 20px;
  height: 2px;
  background: #e6e6e6;
  position: absolute;
  top: 17px;
  left: -40px;
}
@media screen and (max-width: 960px) {
  .history__name:before {
    left: -30px;
    top: 13px;
  }
}
@media screen and (max-width: 670px) {
  .history__name:before {
    display: none;
  }
}
.history__year {
  color: #c8c8c8;
}
.history__info {
  margin-bottom: 20px;
}
@media screen and (max-width: 960px) {
  .history__info {
    font-size: 14px;
    line-height: 1.6;
  }
}
.history__row--reverse .history__item-text {
  text-align: right;
}
@media screen and (max-width: 670px) {
  .history__row--reverse .history__item-text {
    text-align: left;
    order: 1;
  }
}
.history__row--reverse .history__name:before {
  left: auto;
  right: -40px;
}
@media screen and (max-width: 960px) {
  .history__row--reverse .history__name:before {
    right: -30px;
  }
}
.history__row:first-child .history__col {
  padding-top: 20px;
}
@media screen and (max-width: 960px) {
  .history__row:first-child .history__col {
    padding-top: 10px;
  }
}
@media screen and (max-width: 670px) {
  .history__row:first-child .history__col {
    padding-top: 0;
  }
}
.history__row:last-child .history__col {
  padding-bottom: 20px;
}
@media screen and (max-width: 960px) {
  .history__row:last-child .history__col {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 670px) {
  .history__row:last-child .history__col {
    padding-bottom: 0;
  }
}
.about-jobs {
  background: #f6f5f5;
}
@media screen and (max-width: 960px) {
  .about-jobs__col-info {
    order: 1;
    padding: 50px 0 50px;
  }
}
@media screen and (max-width: 670px) {
  .about-jobs__col-info {
    padding: 30px 0;
  }
}
.about-jobs__more {
  padding-right: 40px;
  margin-left: calc((100%*2 - 1340px)/2);
  /* Половина от: 100% ширина экрана - ширина inner (с учетом padding) */
  padding-left: 30px;
  /* Padding как в Inner */
  height: 100%;
}
@media screen and (max-width: 1340px) {
  .about-jobs__more {
    margin-left: 0;
  }
}
@media screen and (max-width: 960px) {
  .about-jobs__more {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 670px) {
  .about-jobs__more {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.about-jobs__info {
  display: none;
  height: 100%;
  transition: all 0.5s;
}
.about-jobs__info--hide {
  display: flex;
  animation: opacityFunctHide 1s 1;
  opacity: 0;
}
.about-jobs__info--active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  animation: opacityFunct 1s 1;
}
.about-jobs__info-title {
  font-size: 25px;
  margin-bottom: 40px;
}
@media screen and (max-width: 1200px) {
  .about-jobs__info-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 670px) {
  .about-jobs__info-title {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
.about-jobs__info-text {
  margin-bottom: 28px;
}
@media screen and (max-width: 1200px) {
  .about-jobs__info-text {
    font-size: 14px;
    margin-bottom: 14px;
  }
}
.about-jobs__info-btn {
  font-size: 13px;
  letter-spacing: 0.1em;
  color: #76beea;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  border: 2px solid #76beea;
  padding: 15px 32px 13px;
  font-family: 'RussoOne', sans-serif;
  transition: all 0.5s;
  margin-top: 20px;
  align-self: flex-start;
}
@media screen and (max-width: 1200px) {
  .about-jobs__info-btn {
    margin-top: 10px;
    font-size: 11px;
    padding: 10px 16px;
  }
}
@media screen and (max-width: 670px) {
  .about-jobs__info-btn {
    margin-top: 5px;
    border-width: 1px;
  }
}
.about-jobs__info-btn:hover {
  color: #ffffff;
  background: #76beea;
}
.about-jobs__item {
  display: block;
  position: relative;
  z-index: 5;
}
.about-jobs__item:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(0, 104, 160, 0);
  transition: all 1s;
}
.about-jobs__item--active:before {
  background: rgba(0, 104, 160, 0.8);
}
.about-jobs__bg {
  z-index: 1;
}
.about-jobs__bg img {
  display: block;
  width: 100%;
}
.about-jobs__title {
  font-size: 20px;
  color: #ffffff;
}
@media screen and (max-width: 1500px) {
  .about-jobs__title {
    font-size: 16px;
  }
}
@media screen and (max-width: 670px) {
  .about-jobs__title {
    font-size: 10px;
  }
}
.about-jobs__inner {
  border: 4px solid rgba(118, 190, 234, 0);
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  transition: all 1s;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: all 0.5s;
}
@media screen and (max-width: 1500px) {
  .about-jobs__inner {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
  }
}
@media screen and (max-width: 960px) {
  .about-jobs__inner {
    width: calc(100% - 60px);
    height: calc(100% - 60px);
  }
}
@media screen and (max-width: 670px) {
  .about-jobs__inner {
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    padding: 10px;
    border-width: 1px;
  }
}
.about-jobs__item--active .about-jobs__inner {
  border: 4px solid #76beea;
  transition: all 2s;
}
@media screen and (max-width: 670px) {
  .about-jobs__item--active .about-jobs__inner {
    border-width: 2px;
  }
}
@keyframes opacityFunct {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes opacityFunctHide {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}
.numbers {
  padding-top: 100px;
  padding-bottom: 120px;
}
@media screen and (max-width: 1200px) {
  .numbers {
    padding-top: 70px;
  }
}
@media screen and (max-width: 960px) {
  .numbers {
    padding-top: 43px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 670px) {
  .numbers {
    padding-top: 30px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 1280px) {
  .numbers__row {
    overflow: hidden;
  }
}
.numbers__num {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .numbers__num {
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
.numbers__moreHundred {
  font-size: 260px;
  font-weight: 900;
  color: #ffffff;
  background: url(../images/about/sky-num.jpg) no-repeat center;
  background-size: contain;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1;
}
@media screen and (max-width: 1280px) {
  .numbers__moreHundred {
    font-size: 200px;
  }
}
@media screen and (max-width: 670px) {
  .numbers__moreHundred {
    font-size: 130px;
  }
}
.numbers__moreHundred span {
  letter-spacing: -0.125em;
}
.numbers__successful {
  font-family: 'RussoOne', sans-serif;
  font-size: 35px;
  text-transform: uppercase;
  color: #1e1e1e;
  letter-spacing: 0.1em;
  padding-left: 25%;
}
@media screen and (max-width: 1280px) {
  .numbers__successful {
    padding-left: 0;
  }
}
@media screen and (max-width: 670px) {
  .numbers__successful {
    font-size: 20px;
  }
}
.numbers__amongThem {
  font-family: 'RussoOne', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 62px;
  line-height: 1;
  color: #e6e6e6;
  position: absolute;
  right: -15px;
  bottom: 0;
  transform: rotate(-90deg);
  white-space: nowrap;
  width: 62px;
}
@media screen and (max-width: 1340px) {
  .numbers__amongThem {
    right: 0;
  }
}
@media screen and (max-width: 1280px) {
  .numbers__amongThem {
    right: 60px;
  }
}
@media screen and (max-width: 1200px) {
  .numbers__amongThem {
    right: 0;
    left: 0;
    margin: auto;
    transform: none;
    width: auto;
    bottom: -85px;
    text-align: center;
  }
}
@media screen and (max-width: 960px) {
  .numbers__amongThem {
    bottom: -100px;
  }
}
@media screen and (max-width: 670px) {
  .numbers__amongThem {
    font-size: 30px;
    bottom: -50px;
  }
}
@media screen and (max-width: 1200px) {
  .numbers__projects-block {
    margin-top: 125px;
  }
}
@media screen and (max-width: 670px) {
  .numbers__projects-block {
    margin-top: 70px;
    padding-bottom: 50px;
  }
}
.numbers__projects {
  margin-left: auto;
  width: 590px;
  height: 425px;
  background: url(../images/about/numbers-builds.png) no-repeat right bottom;
  background-size: 321px 394px;
  position: relative;
}
@media screen and (max-width: 1280px) {
  .numbers__projects {
    margin-left: -50px;
  }
}
@media screen and (max-width: 1200px) {
  .numbers__projects {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 670px) {
  .numbers__projects {
    width: auto;
    background-position: center bottom;
  }
  .numbers__projects:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
  }
}
.numbers__item {
  position: absolute;
  left: 0;
  border-bottom: 1px dashed #d1d1d1;
}
.numbers__item--lahta {
  top: 5px;
  width: 543px;
}
.numbers__item--msk {
  top: 142px;
  width: 467px;
}
.numbers__item--spb {
  top: 181px;
  width: 413px;
}
.numbers__item--evolution {
  top: 213px;
  width: 347px;
}
.numbers__item--lider {
  top: 276px;
  width: 290px;
}
.numbers__item-name {
  font-size: 18px;
  color: #1e1e1e;
}
@media screen and (max-width: 670px) {
  .numbers__item-name {
    font-size: 15px;
  }
}
.numbers__item-hight {
  position: absolute;
  left: 232px;
  font-size: 18px;
  color: #0068a0;
}
@media screen and (max-width: 670px) {
  .numbers__item-hight {
    font-size: 15px;
  }
}
.numbers__all-projects {
  position: absolute;
  left: 0;
  bottom: 0;
  font-family: 'RussoOne', sans-serif;
  font-size: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #0068a0;
  text-decoration: none;
  padding-right: 45px;
  transition: all 0.5s;
}
@media screen and (max-width: 670px) {
  .numbers__all-projects {
    font-size: 12px;
    padding-right: 30px;
    bottom: -45px;
  }
}
.numbers__all-projects:after {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  top: -3px;
  background: url(../images/arrow-top-contact-white.svg) no-repeat center, #76beea;
  background-size: 11px 11px;
}
@media screen and (max-width: 670px) {
  .numbers__all-projects:after {
    width: 20px;
    height: 20px;
    background-size: 5px 5px;
  }
}
.numbers__all-projects:hover {
  opacity: 0.8;
}
.numbers__all-projects--text {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
}
@media screen and (max-width: 670px) {
  .numbers__all-projects--text {
    padding-bottom: 2px;
  }
}
.numbers__all-projects--text:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #0068a0;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 670px) {
  .numbers__all-projects--text:after {
    height: 1px;
  }
}
.team {
  background: #f6f5f5;
  padding-top: 90px;
  padding-bottom: 90px;
}
@media screen and (max-width: 960px) {
  .team {
    padding-top: 70px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 670px) {
  .team {
    padding-top: 50px;
    padding-bottom: 35px;
  }
}
.team__title {
  text-align: center;
  margin-bottom: 60px;
}
@media screen and (max-width: 960px) {
  .team__title {
    margin-bottom: 39px;
  }
}
@media screen and (max-width: 670px) {
  .team__title {
    margin-bottom: 30px;
  }
}
.team__row {
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .team__row {
    margin-left: -10px;
    margin-right: -10px;
  }
}
.team__col {
  width: 20%;
  margin-bottom: 32px;
}
@media screen and (max-width: 1200px) {
  .team__col {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 960px) {
  .team__col {
    width: 33.33%;
  }
}
@media screen and (max-width: 670px) {
  .team__col {
    width: 50%;
    margin-bottom: 14px;
  }
}
.team__item {
  height: 100%;
  background: #ffffff;
}
.team__photo img {
  width: 100%;
}
.team__text {
  padding: 20px 27px 24px;
}
@media screen and (max-width: 1200px) {
  .team__text {
    padding: 15px 20px 17px;
  }
}
@media screen and (max-width: 670px) {
  .team__text {
    padding: 5px 10px 10px;
  }
}
.team__name {
  font-size: 20px;
  font-weight: 500;
  color: #1e1e1e;
  margin-bottom: 5px;
}
@media screen and (max-width: 670px) {
  .team__name {
    font-size: 18px;
  }
}
.team__position {
  font-size: 15px;
}
@media screen and (max-width: 670px) {
  .team__position {
    font-size: 14px;
  }
}
.contact-form {
  background: url(../images/about/contact-form-bg.jpg) no-repeat center;
  background-size: cover;
  padding-top: 110px;
  padding-bottom: 105px;
}
@media screen and (max-width: 960px) {
  .contact-form {
    padding-top: 70px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 670px) {
  .contact-form {
    padding-top: 50px;
    padding-bottom: 35px;
  }
}
.contact-form__title {
  text-align: center;
  color: #ffffff;
  margin-bottom: 45px;
}
@media screen and (max-width: 670px) {
  .contact-form__title {
    margin-bottom: 30px;
  }
}
.contact-form__row {
  margin-left: -20px;
  margin-right: -20px;
}
@media screen and (max-width: 960px) {
  .contact-form__row {
    padding-left: -16px;
    padding-right: -16px;
  }
}
@media screen and (max-width: 670px) {
  .contact-form__row {
    margin-left: -7px;
    margin-right: -7px;
  }
}
.contact-form__col {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;
}
@media screen and (max-width: 960px) {
  .contact-form__col {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 670px) {
  .contact-form__col {
    padding-left: 7px;
    padding-right: 7px;
    margin-bottom: 0;
  }
}
.contact-form__col--send {
  text-align: center;
}
.form__item {
  margin-bottom: 20px;
  width: 100%;
}
@media screen and (max-width: 670px) {
  .form__item {
    margin-bottom: 15px;
  }
}
.form__item--text {
  height: calc(100% - 20px);
}
@media screen and (max-width: 670px) {
  .form__item--text {
    height: auto;
  }
}
.form__item--btn {
  text-align: center;
  margin-top: 20px;
}
@media screen and (max-width: 960px) {
  .form__item--btn {
    margin-top: 0;
  }
}
.form__additional-text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}
.form__message {
  margin-top: 20px;
}
@media screen and (max-width: 670px) {
  .form__message {
    font-size: 12px;
    margin-top: 10px;
  }
}
.form__message--ok {
  color: #A8E4A0;
  font-weight: 500;
  display: none;
}
.form__message--ok--visible {
  display: block;
}
.form__message--error {
  color: red;
  font-weight: 500;
  display: none;
}
.form__message--error--visible {
  display: block;
}
/*Input*/
.form__input {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #ffffff;
  border: 2px solid #ffffff;
  width: 100%;
  height: 50px;
  padding-left: 16px;
  font-size: 16px;
  background-color: transparent;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.form__input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}
.form__input::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
.form__input:-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
.form__input:-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}
@media screen and (max-width: 670px) {
  .form__input {
    height: 42px;
    font-size: 14px;
  }
}
.form__input--error {
  border-color: red;
  color: red;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.form__input--error::-webkit-input-placeholder {
  color: red;
  opacity: 1;
}
.form__input--error::-moz-placeholder {
  color: red;
  opacity: 1;
}
.form__input--error:-moz-placeholder {
  color: red;
  opacity: 1;
}
.form__input--error:-ms-input-placeholder {
  color: red;
  opacity: 1;
}
/*Textares*/
.form__textarea {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #ffffff;
  border: 2px solid #ffffff;
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-top: 13px;
  font-size: 16px;
  background-color: transparent;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.form__textarea::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}
.form__textarea::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
.form__textarea:-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
.form__textarea:-ms-input-placeholder {
  color: #ffffff;
  opacity: 1;
}
@media screen and (max-width: 670px) {
  .form__textarea {
    font-size: 14px;
  }
}
/*button*/
.form__submit-btn {
  font-family: 'RussoOne', sans-serif;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.1em;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  border: 2px solid #76beea;
  background: #76beea;
  padding: 15px 32px 13px;
  transition: all 0.5s;
  margin: auto;
  width: 320px;
}
@media screen and (max-width: 670px) {
  .form__submit-btn {
    font-size: 11px;
    border-width: 1px;
    width: auto;
    padding: 13px 50px;
  }
}
.form__submit-btn:hover {
  color: #76beea;
  background: transparent;
}
