.promo-numbers {  
  position: relative;
  padding-bottom: 50px;

  @media screen and (max-width: 670px) {
    background: url(../images/map-bg.png) no-repeat top right;
    background-size: 292px 163px;
    padding-bottom: 20px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: -20px;
    right: 100px;
    background: url(../images/numbers-bg.png) no-repeat bottom right;
    background-size: 501px 753px;
    width: 501px;
    height: 753px;
    z-index: 4;

    @media screen and (max-width: 1280px) {
      display: none;
    }
  }

  &__main {
    min-height: 313px;
    background: url(../images/map-bg.png) no-repeat 43px 19px;
    background-size: 527px 293px;

    @media screen and (max-width: 1280px) {
      background-size: contain;
      background-position: top right;
    }
    @media screen and (max-width: 670px) {
      background: none;
      min-height: initial;      
    }
  }
  &__title {
    font-size: 35px;
    font-family: 'RussoOne', sans-serif;
    letter-spacing: 0.1em;
    color: #1e1e1e;
    padding-top: 15px;

    @media screen and (max-width: 960px) {
      font-size: 30px;
    }
    @media screen and (max-width: 670px) {
      font-size: 18px;
      padding-bottom: 10px;
    }
  }
  &__num-block {
    padding-top: 15px;
  }
  &__item {
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5;
  }
  &__num {
    font-weight: 700;
    color: #0068a0;
    font-size: 35px;

    @media screen and (max-width: 670px) {
      font-size: 21px;
    }
  }
  &__num-text {
    color: #1e1e1e;

    @media screen and (max-width: 960px) {
      font-size: 14px;
    }
  }
}