.submenu {
  display: none;
  position: absolute;
  left: -30px;
  top: 100%;
  list-style: none;

  @media screen and (max-width: 960px) {
    position: relative;
    left: auto;
    top: auto;

    &--open {
      display: block;
    }
  }

  &__item {
    background-color: #0068a0;
    border-bottom: 1px solid #3386b3;
    
    position: relative; 

    &:last-child {
      border-bottom: 0;
    }
  }
  &__link {
    font-size: 15px;
    color: #ffffff;
    text-decoration: none;   
    padding: 10px 35px 10px 30px;
    display: block; 
    position: relative;
    width: 350px;   

    @media screen and (max-width: 960px) {
      font-size: 14px;
      width: 100%;
    }

    &:after {
      content: '';
      display: block;
      width: 11px;
      height: 9px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 20px;
      background: url(../images/menu-arrow.svg);
    }

    &:hover,
    &--active,
    &--open {
      background-color: #76beea;
    }
  }
  &__submenu {
    display: none;   

     @media screen and (max-width: 960px) {
      position: relative;
      left: auto;
      top: auto;
      padding-left: 50px;
      list-style: none;

      &--open {
        display: block;
      }
      .submenu__link {
        background: transparent;

        &--active,
        &--open {
          background-color: #76beea;
        }
      }
     }
  }
}
.submenu__item:hover .submenu__submenu {
  @media screen and (min-width: 961px) {
    display: block;
    position: absolute;
    left: 100%;
    top: 0;
    list-style: none;
  }

}