.our-projects {
  padding-top: 70px;
  padding-bottom: 120px;
  background: #f5f5f5;

  @media screen and (max-width: 670px) {
    padding-top: 30px;
    padding-bottom: 35px;
  }

  &__title-block {
    display: flex;
    justify-content: space-between;
    color: #1e1e1e;
    align-content: center;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 40px;

    @media screen and (max-width: 670px) {
      display: block;
      margin-bottom: 25px;
    }
  }
  &__btn-all {
    font-family: 'RussoOne', sans-serif;
    font-size: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #0068a0;
    position: relative;
    text-decoration: none;
    padding-right: 45px;
    transition: all 0.5s;

    @media screen and (max-width: 670px) {
      display: none;
      font-size: 11px;
      padding-right: 25px;

    }

    &:after {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      top: -3px;
      background: url(../images/arrow-top-contact-white.svg) no-repeat center,
            #76beea;
      background-size: 11px 11px;

      @media screen and (max-width: 670px) {
        width: 15px;
        height: 15px;
        background-size: 5px 5px;
        top: 0;
      }
    }
    &:hover {
      opacity: 0.8;
    }
    &--text {
      position: relative;
      display: inline-block;
      padding-bottom: 5px;

      @media screen and (max-width: 670px) {
        padding-bottom: 2px;
      }

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: #0068a0;
        position: absolute;
        bottom: 0;
        left: 0;

        @media screen and (max-width: 670px) {
          height: 1px;
        }
      }
    }
  }
  &__btn-all--mob {
    display: none;

    @media screen and (max-width: 670px) {
      display: inline-block;
      margin-top: 15px;
    }
  }

}

