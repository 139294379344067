.types-jobs {
  position: relative;
  z-index: 6;
  background-image: linear-gradient( to bottom, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 100% );
 
  &__item {
    position: relative;
    display: block;
  }
  &__bg {
    img {
      display: block;
    }
  }
  .col {
    margin-bottom: 14px;
  }

  &__info {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0; right: 0;
    top: 0; bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.5s;
    background: rgba(0,104,160,0);
    overflow: hidden;

    &:hover {
      background: rgba(0,104,160,0.8);
    }
  }
  &__inner {
    border: 4px solid rgba(118,190,234,0);
    width: 86.5%;
    height: 86.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.5s;
    padding: 20px;
    position: relative;

    &:before {
      content: '';
      display: block;
      opacity: 0;
      transition: all 0.5s;
      width: 40px;
      height: 40px;
      position: absolute;
      right: -2px;
      top: -2px;
      background: #76beea;
    }
    &:after {
      content: '';
      display: block;
      opacity: 0;
      transition: all 0.7s;
      width: 11px;
      height: 11px;
      background: url(../images/arrow-more-info-white.svg) no-repeat;
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
  &__title {
    font-size: 22px;
    color: #ffffff;
    text-transform: uppercase;
    transition: all 0.5s;   
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0; right: 0;
    margin: auto;

    @media screen and (max-width: 960px) {
      font-size: 16px;
    }

    @media screen and (max-width: 670px) {
      font-size: 11px;
    }

  }
  &__text {
    // display: none;
    transition: all 0.5s;
    color: #ffffff;    
    margin-top: 25px;
    opacity: 0;
    transform: translateY(100px);
    width: 100%;
  }
}
@media screen and (min-width: 961px) {
  .types-jobs__info:hover {

    .types-jobs__inner {
      border-color: #76beea;

      &:before,
      &:after {
        opacity: 1;
        transition: all 0.5s;
      }
      &:after {
        transition: all 1.5s;
      }
    }
    .types-jobs__title {
     top: auto;
     transform: translateY(0);
     position: relative;

     left: auto; right: auto;
     margin: 0;
   }
   .types-jobs__text {
    // display: block;
    opacity: 1;
    transform: translateY(0px);
  }
}
}