.promo-slider-block {
  margin-top: -90px;
  height: 800px; 

  @media screen and (max-width: 1280px) {
    height: 600px;
  }
  @media screen and (max-width: 960px) {
    margin-top: 0;
  }

  @media screen and (max-width: 670px) {
    height: auto;
  }
}
.promo-slider {

  &__item {
    position: relative;
    display: block;
  }

  &__container {
    width: 100%;
    max-width: 1340px;
    padding: 0 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: 21%;
    bottom: 18%;
    margin: auto;

    @media screen and (max-width: 1280px) {
      top: 19%;
      bottom: 10%;
    }
    @media screen and (max-width: 960px) {
      top: 10%;
    }

    @media screen and (max-width: 670px) {
      padding: 0 15px;
      top: 40px;
      bottom: 8%;
    }
  }
  &__info {
    width: 640px;
    max-width: 100%;
    position: relative;
    height: 100%;
  }
  &__title {
    font-family: 'RussoOne', sans-serif;
    font-weight: 400;
    font-size: 35px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #ffffff;
    text-transform: uppercase;

    @media screen and (max-width: 1280px) {
      font-size: 30px;
    }

    @media screen and (max-width: 670px) {
      font-size: 18px;
    }
  }
  &__object {
    width: 324px;
    min-height: 274px;
    border-top: 4px solid #ffffff;
    border-bottom: 4px solid #ffffff;
    border-right: 4px solid #ffffff;
    position: absolute;
    bottom: 0;
    right: 0;

    @media screen and (max-width: 670px) {
      position: relative;
      bottom: auto;
      right: auto;
      margin-top: 35px;
      width: 200px;
      min-height: 160px;
      border-width: 2px;
      margin-left: 30px;
    }

    &:before {
      content: '';
      display: block;
      width: 4px;
      height: 100%;
      background-image: linear-gradient(to bottom, #ffffff 30px, rgba(255,255,255,0) 30px, rgba(255,255,255,0) 100px, #ffffff 100px, #ffffff 100%);
      position: absolute;
      top: 0; bottom: 0;
      left: 0; 

      @media screen and (max-width: 670px) {
        width: 2px;
        background-image: linear-gradient(to bottom, #ffffff 15px, rgba(255,255,255,0) 15px, rgba(255,255,255,0) 50px, #ffffff 50px, #ffffff 100%);
      }
    }    
  }
  &__object-more {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: -4px;
    right: -4px;
    background: url(../images/arrow-more-info.svg) no-repeat center,  #ffffff;
    z-index: 5;

    @media screen and (max-width: 670px) {
      width: 20px;
      height: 20px;
      bottom: -2px;
      right: -2px;
      background-size: 5px 5px;
    }
  }
  &__object-title {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 40px;
    position: absolute;
    top: 35px;
    right: 50%;
    color: #ffffff;
    width: max-content;
    text-transform: none;

    @media screen and (max-width: 670px) {
      top: 15px;
      left: -32px;
      font-size: 20px;
    }
  }
  &__object-text {
    padding: 110px 30px 30px 30px;
    font-size: 17px;
    line-height: 1.6;
    color: #ffffff;

    @media screen and (max-width: 670px) {
      font-size: 12px;
      padding: 60px 10px 15px 10px;
    }
  }

  &__img-bg {
    height: 800px;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1280px) {
      height: 600px;
    }

    @media screen and (max-width: 670px) {
      width: 100% !important;
      max-height: 450px;

      img {
        max-height: 450px;
      }
    }
  }

}

.promo-slider.owl-carousel .owl-item .promo-slider__img-bg--desc {
  width: auto;
  height: 100%;
  max-width: initial;

  @media screen and (max-width: 670px) {
    display: none;
  }
}
.promo-slider.owl-carousel .owl-item .promo-slider__img-bg--mob {
  display: none;

  @media screen and (max-width: 670px) {
    display: block;
  }
}
.promo-slider.owl-carousel .owl-dots {
  position: absolute;
  // bottom: 150px;
  bottom: 18%;
  width: 100%;
  max-width: 1340px;
  padding: 0 30px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  pointer-events: none;

  @media screen and (max-width: 1280px) {
    bottom: 10%;
  }

  @media screen and (max-width: 670px) {
    bottom: 8%;
    padding: 0 15px;
  }
}
.promo-slider.owl-carousel .owl-dot {
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(255,255,255,0.3);
  margin-right: 10px;
  position: relative;
  pointer-events: auto;

  @media screen and (max-width: 670px) {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0; right: 0; 
    top: 0; bottom: 0;
    font-size: 15px;
    font-weight: 500;   
    line-height: 30px; 
    color: rgba(255,255,255,0.3);

    @media screen and (max-width: 670px) {
      font-size: 10px;
      line-height: 20px;
    }
  }
  &:nth-child(1):before {
    content: '1';
  }
  &:nth-child(2):before {
    content: '2';
  }
  &:nth-child(3):before {
    content: '3';
  }
  &:nth-child(4):before {
    content: '4';
  }
  &:nth-child(5):before {
    content: '5';
  }
  &:nth-child(6):before {
    content: '6';
  }
  &:nth-child(7):before {
    content: '7';
  }
  &:nth-child(8):before {
    content: '8';
  }
  &:nth-child(9):before {
    content: '9';
  }
  &:nth-child(10):before {
    content: '10';
  }
}
.promo-slider.owl-carousel .owl-dot.active {
  background-color: #0068a0;
  border-color: #0068a0;

  &:before {
    color: #ffffff;
  }
}
