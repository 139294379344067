.breadcrumbs {
  padding-top: 40px;
  margin-bottom: 40px;

  @media screen and (max-width: 960px) {
    padding-top: 20px;
    margin-bottom: 20px;
  }

  &__item {
    display: inline-block;    
    color: #1e1e1e;
    font-size: 13px;  

    @media screen and (max-width: 670px) {
      font-size: 12px;
    }
  }
  &__link {
    color: #969696;
    text-decoration: none;

    &:after {
      content: '/';
      padding-left: 10px;
      padding-right: 7px;
    }
  }
}