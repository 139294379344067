.social {
  display: flex;
  margin-top: 15px;
  align-content: center;
  align-items: center;

  @media screen and (max-width: 960px) {
    margin-bottom: 30px;
  }

  &__item {
    margin-right: 20px;
    fill: #ffffff;
    display: flex;

    @media screen and (max-width: 670px) {
      margin-right: 10px;
    }

    &:hover {
      fill: #76beea;
    }

    
    &--inst svg {
      width: 19px;
      height: 18px;

      @media screen and (max-width: 670px) {
        width: 15px;
        height: 15px;
      }
    }
   
    &--vk svg {
      width: 23px;
      height: 13px;

      @media screen and (max-width: 670px) {
        width: 19px;
        height: 10px;
      }
    }
   
    &--fb svg {
      width: 11px;
      height: 18px;

      @media screen and (max-width: 670px) {
        width: 9px;
        height: 15px;
      }
    }
  }
}