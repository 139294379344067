.numbers {
  padding-top: 100px;
  padding-bottom: 120px;

  @media screen and (max-width: 1200px) {
    padding-top: 70px;
  }
  @media screen and (max-width: 960px) {
    padding-top: 43px;
    padding-bottom: 80px;
  }
  @media screen and (max-width: 670px) {
    padding-top: 30px;
    padding-bottom: 50px;
  }

  &__row {
    @media screen and (max-width: 1280px) {
      overflow: hidden;
    }
  }

  &__num {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;   
    position: relative;   

    @media screen and (max-width: 1200px) {
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }
  &__moreHundred {
    font-size: 260px;
    font-weight: 900;
    // color: #2ea2f2;
    color: #ffffff;

    background: url(../images/about/sky-num.jpg) no-repeat center;
    background-size: contain;

    background-clip:text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;    
    line-height: 1;

    @media screen and (max-width: 1280px) {
      font-size: 200px;
    }
    @media screen and (max-width: 670px) {
      font-size: 130px;
    }

    span {
      letter-spacing: -0.125em;
    }
  }
  &__successful {
    font-family: 'RussoOne', sans-serif;
    font-size: 35px;
    text-transform: uppercase;
    color: #1e1e1e;
    letter-spacing: 0.1em;
    padding-left: 25%;

    @media screen and (max-width: 1280px) {
      padding-left: 0;
    }
    @media screen and (max-width: 670px) {
      font-size: 20px;
    }
  }
  &__amongThem {
    font-family: 'RussoOne', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 62px;
    line-height: 1;
    color: #e6e6e6;
    position: absolute;
    right: -15px;
    bottom: 0;
    transform: rotate(-90deg);
    white-space: nowrap;
    width: 62px;

    @media screen and (max-width: 1340px) {
      right: 0;
    }
    @media screen and (max-width: 1280px) {
      right: 60px;
    }
    @media screen and (max-width: 1200px) {
      right: 0; left: 0;
      margin: auto;
      transform: none;
      width: auto;
      bottom: -85px;
      text-align: center;
    }
    @media screen and (max-width: 960px) {
      bottom: -100px;
    }
    @media screen and (max-width: 670px) {
      font-size: 30px;
      bottom: -50px;
    }
  }

  &__projects-block {
    @media screen and (max-width: 1200px) {
      margin-top: 125px;
    }
    @media screen and (max-width: 670px) {
      margin-top: 70px;
      padding-bottom: 50px;
    }
  }
  &__projects {
    margin-left: auto;
    width: 590px;
    height: 425px;
    background: url(../images/about/numbers-builds.png) no-repeat right bottom;
    background-size: 321px 394px;
    position: relative;

    @media screen and (max-width: 1280px) {
      margin-left: -50px;
    }
    @media screen and (max-width: 1200px) {
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (max-width: 670px) {
      width: auto;
      background-position: center bottom;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0; right: 0;
        top: 0; bottom: 0;
        background: rgba(255,255,255,0.8);
      }
    }
  }
  &__item {
    position: absolute;
    left: 0;
    border-bottom: 1px dashed #d1d1d1;

    &--lahta {
      top: 5px;
      width: 543px;      
    }
    &--msk {
      top: 142px;
      width: 467px;
    }
    &--spb {
      top: 181px;
      width: 413px;
    }
    &--evolution {
      top: 213px;
      width: 347px;
    }
    &--lider {
      top: 276px;
      width: 290px;
    }
  }
  &__item-name {
    font-size: 18px;
    color: #1e1e1e;

    @media screen and (max-width: 670px) {
      font-size: 15px;
    }
  }
  &__item-hight {
    position: absolute;
    left: 232px;
    font-size: 18px;
    color: #0068a0;

    @media screen and (max-width: 670px) {
      font-size: 15px;
    }
  }
  &__all-projects {
    position: absolute;
    left: 0;
    bottom: 0;

    font-family: 'RussoOne', sans-serif;
    font-size: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #0068a0;
    text-decoration: none;
    padding-right: 45px;
    transition: all 0.5s;

    @media screen and (max-width: 670px) {
      font-size: 12px;
      padding-right: 30px;
      bottom: -45px;
    }

    &:after {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      top: -3px;
      background: url(../images/arrow-top-contact-white.svg) no-repeat center,
            #76beea;
      background-size: 11px 11px;

      @media screen and (max-width: 670px) {
        width: 20px;
        height: 20px;
        background-size: 5px 5px;
      }
    }

     &:hover {
      opacity: 0.8;
    }

    &--text {
      position: relative;
      display: inline-block;
      padding-bottom: 5px;

      @media screen and (max-width: 670px) {
        padding-bottom: 2px;
      }

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: #0068a0;
        position: absolute;
        bottom: 0;
        left: 0;

        @media screen and (max-width: 670px) {
          height: 1px;
        }
      }
    }
  }
}