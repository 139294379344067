.fansybox-photo-modal .fancybox-close {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 30px;
  right: 30px;
  background: url(../images/close-modal-photo.svg) no-repeat;
  cursor: pointer;
  transition: all 0.4s;
  z-index: 9999;
  background-size: contain;

  @media screen and (max-width: 670px) {
    top: 5px;
    right: 5px;
  }

  &:hover {
      transform: rotate(180deg);
    }
}

.fansybox-photo-modal .fancybox-next span,
.fansybox-photo-modal .fancybox-prev span {
  display: block;
  width: 40px;
  height: 40px;
  /*border: 1px solid #ffffff;*/
  position: absolute;
  opacity: 1;
  visibility: visible;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    fill: #ffffff;
    fill-rule: evenodd;
    width: 30px;
    height: 30px;
  }
}
.fansybox-photo-modal .fancybox-prev span {
  left: 10px;

  svg {
    transform: rotate(180deg);

    &:hover {
      animation: photo-fansy-nav-left .3s 3; 
    }
  }
}
.fansybox-photo-modal .fancybox-next span {
  right: 10px;
  svg {

    &:hover {
      animation: photo-fansy-nav-right .3s 3; 
    }
  }
}
@keyframes photo-fansy-nav-left {
  0% {
    left: 0;
  }
  25% {
    left: -5px;
  }
  50% {
    left: 0;
  }
  75% {
    left: 5px;
  }
  100% {
    left: 0;
  }
}
@keyframes photo-fansy-nav-right {
  0% {
    right: 0;
  }
  25% {
    right: -5px;
  }
  50% {
    right: 0;
  }
  75% {
    right: 5px;
  }
  100% {
    right: 0;
  }
}