.trus-us {

  margin-top: 135px;
  margin-bottom: 135px;

  @media screen and (max-width: 960px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    @media screen and (max-width: 960px) {
      display: block;
    }
  }

  &__title-block {
    width: 215px;

    @media screen and (max-width: 960px) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  &__slider {
    width: calc( 100% - 215px );

    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }

  &__title {
    color: #1e1e1e;
    text-transform: uppercase;
  }
}
@media screen and (max-width: 670px) {

  .trus-us__inner {
    padding-right: 0;
  }
}