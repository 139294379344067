* {	
	margin: 0;
	padding: 0;
	border: none;
	box-sizing: border-box;
	outline: none;
}
main,
article,
section,
footer,
header,
aside,
canvas {
    display: block;
}
section,
header,
footer {
    position: relative;
}
html {
	height: 100%;
}
body {
	min-width: 320px;
	min-height: 100%;
  background-color: #ffffff;

  font-family: 'Roboto', sans-serif;

	font-weight: 300;
	line-height: 1.4;
	letter-spacing: 0em;	
	color: #646464;

	position: relative;
  font-size: 16px;


	@media screen and (max-width: 1920px) { position: relative; } 
  @media screen and (max-width: 1600px) { position: relative; } 
  @media screen and (max-width: 1500px) { position: relative; }
  @media screen and (max-width: 1440px) { position: relative; }  
  @media screen and (max-width: 1340px) { position: relative; } /*inner 1280 + 30px*2 */
  @media screen and (max-width: 1280px) { position: relative; }  
  @media screen and (max-width: 1200px) { position: relative; } 
  @media screen and (max-width: 1100px) { position: relative; } 
  @media screen and (max-width: 1024px) { position: relative; }  
	@media screen and (max-width: 960px) { position: relative; }
	@media screen and (max-width: 670px) { position: relative; }	
	@media screen and (max-width: 576px) { position: relative; }

  @media screen and (min-width: 961px) { position: relative; }

  &.on-popup {
    overflow: hidden;
  }
}
// .inner {
// 	position: relative;
// 	max-width: 1920px;
// 	height: 100%;
// 	margin: 0 auto;
// 	padding: 0 15px;	

// 	@media screen and (max-width: 670px) {
// 		overflow: hidden;
// 	}
// }
// .inner:after {
//     content: '';
//     display: block;
//     clear: both;
// }
section:after {
	content: '';
  display: block;
  clear: both;
}
button, input, select {
  font-family: 'Roboto', sans-serif;  
  font-weight: 300;
}
button {
  cursor: pointer;
  line-height: 1.4;
}
h1, h2, h3 {
  font-family: 'RussoOne', sans-serif;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #1e1e1e;
}
h1 {	
  font-size: 35px;
  text-align: center;

  @media screen and (max-width: 670px) {
    font-size: 18px;
  }
}
h2 {
  font-size: 25px;

  @media screen and (max-width: 670px) {
    font-size: 16px;
  }
}
h3 {	
  font-size: 21px;

  @media screen and (max-width: 670px) {
    font-size: 14px;
  }
}
strong {
	font-weight: 500;
}
img {
	max-width: 100%;
}
p {
 
}
a {
    color: #0068a0;
}