.footer {
  padding-top: 45px;
  padding-bottom: 45px;
  background: #1e1e1e;

  @media screen and (max-width: 670px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__main {
    margin-bottom: 70px;
    overflow: hidden;

    @media screen and (max-width: 960px) {
      margin-bottom: 50px;
    }
    @media screen and (max-width: 670px) {
      margin-bottom: 35px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
  }
  &__logo {
    margin-bottom: 30px;

    @media screen and (max-width: 670px) {
      max-width: 85%;
      margin-bottom: 15px;
    }
  }
  &__phone {
    font-size: 25px;
    font-weight: 500;
    color: #ffffff;
    text-decoration: none;
    margin-bottom: 15px;

    @media screen and (max-width: 670px) {
      font-size: 14px;
      margin-bottom: 7px;
    }
  }
  &__email {
    font-size: 14px;
    color: #0068a0;
    text-decoration: none;
    margin-bottom: 15px;

    @media screen and (max-width: 670px) {
      margin-bottom: 7px;
    }
  }
  &__venue {
    font-size: 14px;
    line-height: 1.7;
    color: #ffffff;
    margin-bottom: 15px;

    @media screen and (max-width: 670px) {
      margin-bottom: 7px;
    }
  }
  &__title {
    margin-bottom: 45px;
    font-size: 16px;
    font-family: 'RussoOne', sans-serif;
    color: #ffffff;
    text-transform: uppercase;
    margin-top: 25px;
    letter-spacing: 0.1em;

    @media screen and (max-width: 960px) {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 670px) {
      margin-bottom: 15px;
      margin-top: 20px;
      font-size: 12px;
    }
  }
  &__another {
    font-size: 13px;
    color: rgba(255,255,255,0.2);

    @media screen and (max-width: 670px) {
      font-size: 11px;
    }

    a {
      color: rgba(255,255,255,0.2);
      text-decoration: none;

      &:hover {
        color: rgba(255,255,255,0.5);
      }
    }
  }
  &__col-jobs {
    @media screen and (max-width: 960px) {
      order: 1;
    }
    @media screen and (max-width: 670px) {
      order: 0;
    }
  }
  &__col-company {
    @media screen and (max-width: 670px) {
      margin-top: -110px;
    }
  }
}
