.our-projects-slider {

  &__item {
    display: block;
    position: relative;
  }
  &__text {
    background: #ffffff;
    padding: 23px 25px 28px;

    @media screen and (max-width: 670px) {
      padding: 10px 7px 12px;
    }
  }
  &__direction {
    position: relative;
    z-index: 2;
    display: block;
    font-size: 14px;
    color: #0068a0;
    text-decoration: none;

    @media screen and (max-width: 670px) {
      font-size: 12px;
    }
  }
  &__title {
    font-size: 20px;
    font-weight: 500;
    color: #1e1e1e;
    min-height: 65px;
    margin-top: 10px;  
    margin-bottom: 10px; 

    @media screen and (max-width: 670px) {
      font-size: 14px;
      margin-top: 5px;
      min-height: 35px;
    }

    a {
      color: #1e1e1e;
      text-decoration: none;
    }
  }
  &__venue {
    font-size: 15px;
    padding-left: 27px;
    position: relative;
    margin-bottom: 17px;

    @media screen and (max-width: 670px) {
      padding-left: 10px;
      font-size: 11px;
      margin-bottom: 7px;
    }

    &:before {
      content: '';
      display: block;
      width: 15px;
      height: 18px;
      background: url(../images/icon-venue-blue.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 2px;

      @media screen and (max-width: 670px) {
        width: 7px;
        height: 9px;
      }
    }
  }
  &__tags {
    display: block;
  }
  &__tag {
    position: relative;
    z-index: 2;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
    color: #1e1e1e;
    text-decoration: none;
    border-radius: 20px;
    background: #d6ebf9;
    padding: 3px 13px;

    @media screen and (max-width: 670px) {
      font-size: 11px;
      padding: 1px 5px;
    }
  }
}

.our-projects-slider.owl-carousel .owl-item {
  opacity: 0.3;
  position: relative;

  @media screen and (max-width: 670px) {
    opacity: 1;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      left: 0; right: 0;
      top: 0; bottom: 0;
      background: rgba(0,104,160,0.5);
      position: absolute;
      z-index: 2;
      transition: all 0.5s;
    }
  }
}
.our-projects-slider.owl-carousel .owl-item.active {
  opacity: 1;

  @media screen and (max-width: 670px) {

    &:before {
      opacity: 0; 
      pointer-events: none;
    }

  }
}
.our-projects-slider.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.our-projects-slider.owl-carousel .owl-nav {
  position: absolute;
  width: calc( (100vw - 1310px)/2 );
  height: 100%;
  display: block;
  right: calc( (100vw - 1310px + 30px)/2 *-1 );
  top: 0;
  bottom: 0;
  // background: pink;

  @media screen and (max-width: 1440px) {
    width: calc( 20vw/2 + 30px );
    right: 0;
  }
  @media screen and (max-width: 670px) {
    width: 30px;
    right: -40px;
  }
}
.our-projects-slider.owl-carousel .owl-nav .owl-next {
  width: 100%;
  height: 100%; 
  background: none;
  position: relative;

  &:before {
    content: '';
    display: block;
    background: url(../images/arrow-right-black.svg) no-repeat center, #ffffff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 130px;

    @media screen and (max-width: 670px)  {
      display: none;
    }
  }
}
